import React, { useEffect, useState } from "react";
import SingleImage from "./SingleImage";
import MultipleImage from "./MultipleImage";
import { serverAddress } from "../../config";
import { Global_Data } from "../../globalData/GlobalData";
function ImageComponent({
  setTextValue,
  formAction,
  textValue,
  value,
  imagesIdsFromMedia,
  setImagesIdsFromMedia,
  data,
  openStaticDialog
}) {
  // setImagesIdsFromMedia([{sName: data?.component?.sName,data:textValue[data?.component?.sName]}])
  const { token } = Global_Data();
  function processData(dataString) {
    if (dataString) {
      // Split the string by comma
      const parts = dataString?.split(/,\s*/);
      // Initialize an object to store the result
      const result = {
        featured: null, // Set default featured value
        data: []
      };
      // Iterate over parts and populate the result object
      for (let i = 0; i < parts.length; i++) {
        const trimmedPart = parts[i].trim();
        // Check if the current part is the featured one
        if (trimmedPart.includes(":Featured")) {
          // Extract the featured part and remove ':Featured' suffix
          result.featured = trimmedPart.replace(":Featured", "");
        } else {
          result.data.push(trimmedPart);
        }
      }

      return result;
    }
  }

  const [featuredImg, setFeaturedImg] = useState({});

  useEffect(() => {
    if (data?.component?.options?.mode == "SINGLE") {
      if (imagesIdsFromMedia.length == 0 && value) {
        setImagesIdsFromMedia([{ sName: data?.component?.sName, data: [value] }]);
      }
    } else {
      const processedData = processData(value);
      if (processedData) {
        let arr = processedData?.data.filter(item => item !== "");
        setFeaturedImgsSrc(processedData.featured);
        setImagesIdsFromMedia([
          {
            sName: data?.component?.sName,
            data: processedData?.featured ? [...arr, processedData?.featured] : [...arr]
          }
        ]);
      }
    }
  }, [value]);

  async function setFeaturedImgsSrc(item) {
    if (item) {
      const requestOptions = {
        headers: {
          Authorization: `Bearer ${token}`
        }
      };
      try {
        const response = await fetch(
          serverAddress + `/image/retrieve?imageid=${item}`,
          requestOptions
        );
        const blob = await response.blob();
        const objectURL = URL.createObjectURL(blob);
        setFeaturedImg({ src: objectURL, imgID: item });
        // setimg(pre => [...pre, { ...image, index: ind, src: objectURL }]);
      } catch (error) {
        console.error("Error fetching image:", error);
        throw error; // Rethrow the error for handling elsewhere if needed
      }
    }
  }
  return data?.component?.options?.mode == "SINGLE" ? (
    <SingleImage
      setTextValue={setTextValue}
      data={data}
      value={value}
      formAction={formAction}
      openStaticDialog={openStaticDialog}
      imagesIdsFromMedia={imagesIdsFromMedia}
      setImagesIdsFromMedia={setImagesIdsFromMedia}
      textValue={textValue}
    />
  ) : (
    <>
      <MultipleImage
        setTextValue={setTextValue}
        data={data}
        featuredImgIDs={featuredImg}
        openStaticDialog={openStaticDialog}
        imagesIdsFromMedia={imagesIdsFromMedia}
        setImagesIdsFromMedia={setImagesIdsFromMedia}
        textValue={textValue}
      />
    </>
  );
}

export default ImageComponent;
