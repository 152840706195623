import * as React from "react";

import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import ImageListItemBar from "@mui/material/ImageListItemBar";
import { Box, Button, Checkbox, Grid, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { Global_Data } from "../../globalData/GlobalData";
import IconButton from "@mui/material/IconButton";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import PhotoCameraIcon from "@mui/icons-material/PhotoCamera";
import StarIcon from "@mui/icons-material/Star";
import { serverAddress } from "../../config";
function MultipleImage({
  setTextValue,
  textValue,
  featuredImgIDs,
  imagesIdsFromMedia,
  setImagesIdsFromMedia,
  data,
  openStaticDialog
}) {
  const { token } = Global_Data();
  const [featuredImage, setfeaturedImage] = useState(featuredImgIDs);
  const [images, setImages] = useState([]);

  function isEmpty(obj) {
    return Object.entries(obj).length === 0 && obj.constructor === Object;
  }
  useEffect(() => {
    if (!isEmpty(featuredImgIDs)) {
      setfeaturedImage(featuredImgIDs);
    } else {

      if (images[0]?.imgID) {
       // setfeaturedImage(images[0]);
      }
    }
  }, [featuredImgIDs, images]);
  // console.log(imagesIdsFromMedia);

  const GetImg = async images => {
    const requestOptions = {
      headers: {
        Authorization: `Bearer ${token}`
      }
    };
    const updImg = [];
    if (images?.length > 0) {
      if (data?.component?.options?.mode == "SINGLE") {
        try {
          const response = await fetch(
            serverAddress + `/image/retrieve?imageid=${images[0]}`,
            requestOptions
          );
          const blob = await response.blob();
          const objectURL = URL.createObjectURL(blob);

          updImg.push({ src: objectURL, imgID: images[0] });
          // setimg(pre => [...pre, { ...image, index: ind, src: objectURL }]);
        } catch (error) {
          console.error("Error fetching image:", error);
          throw error; // Rethrow the error for handling elsewhere if needed
        }
        // setTextValue(pre => ({ ...pre, [data?.component?.sName]: images[0] }));
      } else {
        for (let item of images) {
          try {
            const response = await fetch(
              serverAddress + `/image/retrieve?imageid=${item}`,
              requestOptions
            );
            const blob = await response.blob();
            const objectURL = URL.createObjectURL(blob);
            updImg.push({ src: objectURL, imgID: item });
            // setimg(pre => [...pre, { ...image, index: ind, src: objectURL }]);
          } catch (error) {
            console.error("Error fetching image:", error);
            throw error; // Rethrow the error for handling elsewhere if needed
          }
        }

        let valueString = "";
        for (let i = 0; i < images.length; i++) {
          if (featuredImage.imgID == images[i]) {
            valueString += ", " + images[i] + ":Featured";
          } else {
            valueString += ", " + images[i];
          }
        }
        valueString.slice(0, -1);
        if (valueString.startsWith(',')) {
          valueString = valueString.replace(',', '');
        }
        setTextValue(pre => ({ ...pre, [data?.component?.sName]: valueString }));
      }
    }
    // After all images are fetched and updated, set the state
    setImages(updImg);
  };
  function getObjectBySName(name, data) {
    for (let i = data?.length - 1; i >= 0; i--) {
      if (data[i].sName === name) {
        return data[i];
      }
    }
    return null; // Return null if no matching object is found
  }
  useEffect(() => {
    const cdata = getObjectBySName(data?.component?.sName, imagesIdsFromMedia);
    if (cdata) {
      GetImg(cdata?.data);
    }
  }, [imagesIdsFromMedia]);
  useEffect(() => {
    // setTextValue((pre)=>({ ...pre, [data?.component?.sName]:'' }));
    if (!textValue[data?.component?.sName]) {
      textValue[data?.component?.sName] = "";
    }
  }, [textValue]);

  const [removeImageIds, setRemoveImageIds] = useState([]);

  function removeImages() {
   

    const cdata = getObjectBySName(data?.component?.sName, imagesIdsFromMedia);
    if (cdata) {
      const newData = cdata?.data.filter(item => !removeImageIds.includes(item));
      setImages([]);
      setImagesIdsFromMedia([{ sName: data?.component?.sName, data: newData }]);
    }
    setRemoveImageIds([]);
    setTextValue(pre => ({ ...pre, [data?.component?.sName]: "" }));
  }

  // useEffect(() => {
  //   let valueString = "";
  //   for (let i = 0; i < images.length; i++) {
  //     if (featuredImage.imgID == images[i].imgID) {
  //       valueString += images[i].imgID + ":Featured, ";
  //     } else {
  //       valueString += images[i].imgID + ", ";
  //     }
  //   }
  //   // setTextValue(pre => ({ ...pre, [data?.component?.sName]: valueString }));
  //   textValue[data?.component?.sName] = valueString;
  // }, [featuredImage]);

  function addFeatured(featuredImage) {
    let valueString = "";
    for (let i = 0; i < images.length; i++) {
      if (featuredImage.imgID == images[i].imgID) {
        valueString += ", " + images[i].imgID + ":Featured";
      } else {
        valueString += ", " + images[i].imgID;
      }
    }
    // setTextValue(pre => ({ ...pre, [data?.component?.sName]: valueString }));
    valueString.slice(0, -1);
    if (valueString.startsWith(',')) {
      valueString = valueString.replace(',', '');
    }
    textValue[data?.component?.sName] = valueString;
  }


 
  return (
    <Grid item {...data?.grid_props}>
      {/* {data?.component?.sName} */}
      <Grid display={"flex"} gap={"20px"}>
        { !images[0]?.src ? (
          <Box
            {...data.component.sPrimaryContainer.sProps}
            // style={{ border: "1px dashed gray", height: "450px", width: "500px" }}
          >
            <Box {...data.component.sPrimaryImageProps}>
              <PhotoCameraIcon style={{ fontSize: "50px" }} />
            </Box>

          </Box>
        ) : (
          // <Grid
          //   {...data.component.sPrimaryContainer.sProps}
          //   style={{ border: "1px dashed gray", height: "450px", width: "500px" }}
          // >
          <Box
            {...data.component.sPrimaryContainer.sProps}
          >
            <FeaturedImage featuredImage={featuredImage.src || images[0]?.src} img={images} sProps={data?.component?.sPrimaryImageProps} />
          </Box>
        )}
        <Box
          {...data.component.sSecondaryContainer.sProps}
        >
          <CustomImageList
            data={data}
            featuredImgIDs={featuredImgIDs}
            addFeatured={addFeatured}
            removeImageIds={removeImageIds}
            setRemoveImageIds={setRemoveImageIds}
            featuredImage={featuredImage}
            setfeaturedImage={setfeaturedImage}
            itemData={images}
          />
        </Box>
      </Grid>
      <Box
          {...data?.component?.sButtonProps}
      >
        <Button
          onClick={() => openStaticDialog(true, data?.component?.sName)}
          {...data?.component?.sSelectButton?.sProps}
        >
          {data?.component?.sSelectButton?.sLabel}
        </Button>
        <Button onClick={() => removeImages()} {...data?.component?.sRemoveButton?.sProps}>
          {data?.component?.sRemoveButton?.sLabel}
        </Button>
      </Box>
    </Grid>
  );
}

export default MultipleImage;

function FeaturedImage({ img, featuredImage, sProps }) {
  return (
    <>
      <Box {...sProps}>
          {/* <ImageList cols={3} gap={8} > */}
          {/* <ImageList> */}
          {/* {img.map(item => ( */}
          {/* <ImageListItem> */}
          <img
            srcSet={`${featuredImage}`}
            src={`${featuredImage}`}
            // style={{
            //   height: "-webkit-fill-available",
            //   width: "-webkit-fill-available",
            //   margin: 0
            // }}
            // alt={item.title}
            loading="lazy"
          />
          {/* <ImageListItemBar position="below" /> */}
          {/* </ImageListItem> */}

          {/* </ImageList> */}
      </Box>
    </>
  );
}

function CustomImageList({
  removeImageIds,
  setRemoveImageIds,
  data,
  featuredImgIDs,
  itemData,
  addFeatured,
  setfeaturedImage,
  featuredImage
}) {
  itemData.forEach(element => {
    element["featured"];
  });

  const handleRemoved = ids => {
    if (removeImageIds.includes(ids)) {
      const data = removeImageIds.filter(id => id !== ids);
     
      setRemoveImageIds(data);
    } else {
      setRemoveImageIds(pre => [...pre, ids]);
    }
  };
  function isEmpty(obj) {
    return Object.entries(obj).length === 0 && obj.constructor === Object;
  }

  const [isFeatureId, setIsFeatured] = useState(featuredImgIDs);
  useEffect(() => {
    setIsFeatured(featuredImgIDs);
  }, [featuredImgIDs]);
  return (
    <>
      {/* {JSON.stringify(isFeatureId)} */}
      <ImageList
        {...data.component.sThumbnailProps}
        // sx={{
        //   width: 150,
        //   height: 450,

        //   transform: "translateZ(0)"
        // }}
        // rowHeight={80}
        // gap={1}
      >
        {itemData.map(item => {
          const cols = 2;
          const rows = 2;

          return (
            <ImageListItem key={item.img} cols={cols} rows={rows}>
              <img
                onClick={() => setfeaturedImage({ src: item.src, imgID: item.imgID })}
                src={item.src}
                loading="lazy"
                // style={{ cursor: "pointer", height: "150px", width: "150px" }}
                {...data.component.sThumbnailProps}
              />
              <div
                style={{
                  position: "absolute",
                  bottom: "0px",
                  height: "30px",
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  gap: "10px",
                  color: "white",
                  background: "rgba(97, 97, 97, .6)"
                }}
              >
                <Typography onClick={() => handleRemoved(item.imgID)}> Remove</Typography>{" "}
                <input
                  onClick={() => handleRemoved(item.imgID)}
                  checked={removeImageIds.includes(item.imgID)}
                  style={{ width: "20px" }}
                  type="checkbox"
                />
              </div>
              <ImageListItemBar
                sx={{
                  background:
                    "linear-gradient(to bottom, rgba(0,0,0,0.7) 0%, " +
                    "rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)"
                }}
                title={item.title}
                position="top"
                actionIcon={
                  isFeatureId.imgID == item.imgID ? (
                    <IconButton
                      onClick={() => {
                        setfeaturedImage({ src: item.src, imgID: item.imgID });
                        setIsFeatured({ src: item.src, imgID: item.imgID });
                        addFeatured({ src: item.src, imgID: item.imgID });
                      }}
                      sx={{ color: "white" }}
                    >
                      <StarIcon />
                    </IconButton>
                  ) : (
                    <IconButton
                      onClick={() => {
                        setIsFeatured({ src: item.src, imgID: item.imgID });
                        setfeaturedImage({ src: item.src, imgID: item.imgID });
                        addFeatured({ src: item.src, imgID: item.imgID });
                      }}
                      sx={{ color: "white" }}
                    >
                      <StarBorderIcon />
                    </IconButton>
                  )
                }
                actionPosition="left"
              />
            </ImageListItem>
          );
        })}
      </ImageList>
    </>
  );
}
