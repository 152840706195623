import React, { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import { Icon } from "../../utils/MuiIcons/Icon";

function NUMBER({ data, ind, index, error, textValue, handleTextValue }) {

  const [ changedValue ,setChangedValue ] = useState()
  
  useEffect(() => {
    setChangedValue(data?.component?.iDecimalPlaces == 0 ? '0':'0.00')
  },[data])
  // const handlePointChange = (num, decimalPlaces = 0) => {

  //   // let val = inputValue.includes(".") ? parseFloat(formattedValue).toFixed(decimalPlaces) : formattedValue;
  //   let val;
  //   const inputValue = num.toString();
  //   console.log(num, decimalPlaces, "lopo");
  //   if (inputValue.includes(".")) {
  //     const arr = inputValue.split(".");
  //     const lastValue = arr[1];
  //     const newInputValue = inputValue.toString().replace(".", "");
  //     const lastValueueLenght = lastValue.toString().length;
  //     const STRVAL = newInputValue.toString();
  //     const arrval = STRVAL.split("");
  //     const newArr = lastValue.split("");

  //     if (decimalPlaces == 0) {
  //       val = num * 1000;
  //     }

  //     if (decimalPlaces == 1) {
  //       val = lastValue / 10;
  //       if (arr[0] !== "0") {
  //         val = `${arr[0]}${lastValue / 10}`;
  //       }
  //     }
  //     if (decimalPlaces == 2) {
  //       if (lastValueueLenght === 2) {
  //         val = `${arr[0]}.${lastValue}`;
  //       } else {
  //         const joinVal = arrval.slice(0, -2).join("");
  //         val = parseFloat(
  //           `${parseFloat(joinVal)}.${arrval[arrval.length - 2]}${arrval[arrval.length - 1]}`
  //         );
  //       }
  //     }
  //     if (decimalPlaces == 3) {
  //       const joinVal = arrval.slice(0, -3).join("");

  //       if (newArr.length == 3) {
  //         val = `${parseFloat(joinVal)}.${newArr[0]}${newArr[1]}${newArr[2]}`;
  //       } else {
  //         val = `${parseFloat(joinVal)}.${newArr[1]}${newArr[2]}${newArr[3]}`;
  //       }
  //     }
  //     if (decimalPlaces == 4) {
  //       const joinVal = arrval.slice(0, -4).join("");
  //       if (newArr.length == 3) {
  //         val = `${0}.0${newArr[0]}${newArr[1]}${newArr[2]}`;
  //       } else {
  //         val = `${parseFloat(joinVal)}.${newArr[1]}${newArr[2]}${newArr[3]}${newArr[4]}`;
  //       }
  //     }

  //     if (decimalPlaces == 5) {
  //       const joinVal = arrval.slice(0, -5).join("");
  //       if (newArr.length == 3) {
  //         val = `${0}.00${newArr[0]}${newArr[1]}${newArr[2]}`;
  //       } else {
  //         val = `${parseFloat(joinVal)}.${newArr[1]}${newArr[2]}${newArr[3]}${newArr[4]}${
  //           newArr[5]
  //         }`;
  //       }
  //     }
  //   } else {
  //     if (decimalPlaces == 0) {
  //       val = inputValue;
  //       parseFloat(val).toFixed(decimalPlaces);
  //     }
  //     if (decimalPlaces == 1) {
  //       val = `0.${inputValue}`;
  //     }
  //     if (decimalPlaces == 2) {
  //       val = `0.0${inputValue}`;
  //     }
  //     if (decimalPlaces == 3) {
  //       val = `0.00${inputValue}`;
  //     }
  //     if (decimalPlaces == 4) {
  //       val = `0.000${inputValue}`;
  //     }
  //     if (decimalPlaces == 5) {
  //       val = `0.0000${inputValue}`;
  //     }
  //   }
  //   console.log(val, "lopo");
  //   return isNaN(val) ? (decimalPlaces == "0" ? "0" : `${"0".repeat(decimalPlaces - 1)}`) : val;
  // };


  function removeCommas(inputString) {
    let result = "";
    for (let i = 0; i < inputString.length; i++) {
      if (inputString[i] !== ",") {
        result += inputString[i];
      }
    }
    return result;
  }
  const handlePointChange = (num1, decimalPlaces = 0) => {
    // function handlePointChange1(num1, decimalPlaces = 0) {
      if (num1) {
        
      
      const num = removeCommas(num1);
      // let val = inputValue.includes(".") ? parseFloat(formattedValue).toFixed(decimalPlaces) : formattedValue;
      let val;
      const inputValue = num?.toString();
  
      if (inputValue.includes(".")) {
        const arr = inputValue.split(".");
        const lastValue = arr[1];
        const newInputValue = inputValue?.toString().replace(".", "");
        const lastValueueLenght = lastValue?.toString().length;
        const STRVAL = newInputValue?.toString();
        const arrval = STRVAL.split("");
        const newArr = lastValue.split("");
  
        if (decimalPlaces == 0) {
          val = num;
        }
  
        if (decimalPlaces == 1) {
          val = lastValue / 10;
          if (arr[0] !== "0") {
            val = `${arr[0]}${lastValue / 10}`;
          }
        }
        if (decimalPlaces == 2) {
          if (lastValueueLenght === 2) {
            val = `${arr[0]}.${lastValue}`;
          } else {
            const joinVal = arrval.slice(0, -2).join("");
            val = `${parseFloat(joinVal)}.${arrval[arrval.length - 2]}${arrval[arrval.length - 1]}`;
          }
        }
        if (decimalPlaces == 3) {
          const joinVal = arrval.slice(0, -3).join("");
  
          if (newArr.length == 3) {
            val = `${parseFloat(joinVal)}.${newArr[0]}${newArr[1]}${newArr[2]}`;
          } else {
            val = `${parseFloat(joinVal)}.${newArr[1]}${newArr[2]}${newArr[3]}`;
          }
        }
        if (decimalPlaces == 4) {
          const joinVal = arrval.slice(0, -4).join("");
          if (newArr.length == 3) {
            val = `${0}.0${newArr[0]}${newArr[1]}${newArr[2]}`;
          } else {
            val = `${parseFloat(joinVal)}.${newArr[1]}${newArr[2]}${newArr[3]}${newArr[4]}`;
          }
        }
  
        if (decimalPlaces == 5) {
          const joinVal = arrval.slice(0, -5).join("");
          if (newArr.length == 3) {
            val = `${0}.00${newArr[0]}${newArr[1]}${newArr[2]}`;
          } else {
            val = `${parseFloat(joinVal)}.${newArr[1]}${newArr[2]}${newArr[3]}${newArr[4]}${
              newArr[5]
            }`;
          }
        }
      } else {
        if (decimalPlaces == 0) {
          val = inputValue;
          parseFloat(val).toFixed(decimalPlaces);
        }
        if (decimalPlaces == 1) {
          val = `0.${inputValue}`;
        }
        if (decimalPlaces == 2) {
          if (inputValue.length == 1) {
            // val = `0.0${inputValue}`;
            val = `${inputValue}.00`;
          } else {
            val = `${inputValue}.00`;
          }
        }
        if (decimalPlaces == 3) {
          val = `0.00${inputValue}`;
        }
        if (decimalPlaces == 4) {
          val = `0.000${inputValue}`;
        }
        if (decimalPlaces == 5) {
          val = `0.0000${inputValue}`;
        }
      }
  
      return new Intl.NumberFormat("en-US", {
        minimumFractionDigits: decimalPlaces,
        maximumFractionDigits: decimalPlaces
      }).format(
        isNaN(val) ? (decimalPlaces == "0" ? "0" : `${"0"}${"0".repeat(decimalPlaces)}`) : val
      );
      // return isNaN(val) ? decimalPlaces : val;
    }
    }

    
    const [isTextSelected, setIsTextSelected] = useState(false);
  const handleChange = event => {

    if (isTextSelected) {
      // alert(JSON.stringify( e.target.value))
      event.target.value =
      data?.component.iDecimalPlaces == 2? event.target.value.includes(".") ?"0.0"+ event.target.value[event.target.value.length -1] : "0.0" + event.target.value
      : event.target.value
      // alert( data?.component.iDecimalPlaces)
      
    }

    const { value, name } = event.target;


    const value2 = handlePointChange(value, data?.component?.iDecimalPlaces);
    // alert(value2)
    const e = {
      target: {
        name: name,
        value: value2?.toString(),
        decimalPlaces: data?.component?.iDecimalPlaces
      }
    };

    handleTextValue(e, ind, data?.validation);
  };

  const handleFocus = event => {
    const { target } = event;
    const { value } = target;
    // Move cursor to the end
    target.setSelectionRange(value.length, value.length);
  };
  useEffect(()=>{
    // const val = handlePointChange(typeof textValue[data.component.sName] === 'number' ? JSON.stringify(textValue[data.component.sName]) :textValue[data.component.sName],data?.component?.iDecimalPlaces)
    if(textValue[data.component.sName]){
      setChangedValue(Number(String(textValue[data.component.sName])?.replace(/,/g, ''))?.toLocaleString('en-US', { minimumFractionDigits: data?.component?.iDecimalPlaces || 2, maximumFractionDigits: data?.component?.iDecimalPlaces || 2 }))
    }
    // alert( typeof textValue[data.component.sName] === 'number' ? JSON.stringify(textValue[data.component.sName]) :textValue[data.component.sName] )
    // alert('dd')
    },[textValue[data.component.sName]])


    const handleSelectionBlur = event => {
      setIsTextSelected(false);
    };
    const handleSelectionChange = event => {
      const { selectionStart, selectionEnd } = event.target;
      setIsTextSelected(selectionStart !== selectionEnd);
    };



    
  
  return (
    <Grid item {...data?.grid_props}>
      {/* {textValue[data?.component?.sName] && JSON.stringify(textValue[data?.component?.sName])?.replace(/\B(?=(\d{3})+(?!\d))/g,',')} */}
      {/* <div className="select-box-v"> */}
      {/* {  handlePointChange(textValue[data?.component?.sName], '2')} */}
      {/* {changedValue} */}
      {/* <br /> */}
      {/* { textValue[data?.component?.sName] ?textValue[data?.component?.sName] && handlePointChange(parseFloat(textValue[data?.component?.sName],'2' ))+""?.replace(/\B(?=(\d{3})+(?!\d))/g,','):'0.00'} */}
      {/* {handlePointChange(parseFloat(textValue[data?.component?.sName] || '00').toFixed(2), 2)} */}
      {/* {textValue[data?.component?.sName] && handlePointChange(textValue[data?.component?.sName], 2)} */}
      {/* { textValue[data?.component?.sName] && handlePointChange(textValue[data?.component?.sName], data?.component?.iDecimalPlaces).toString()} */}
      <TextField
        {...data?.component?.sProps}
        id={data?.component?.sName}
        label={data?.component?.sLabel}
        placeholder={data?.component?.sPlaceHolder}
        variant={data?.component?.sProps?.variant}
        inputProps={{  style: { textAlign: data?.component?.sJustify }, }}
        type={data?.component?.sAdornType}

        // value={ textValue[data?.component?.sName] ?textValue[data?.component?.sName] && handlePointChange(parseFloat(textValue[data?.component?.sName],'2' ))+""?.replace(/\B(?=(\d{3})+(?!\d))/g,','):'0.00'}
        value={changedValue}
  
        name={data?.component?.sName}
        onChange={e => handleChange(e, ind, data?.validation)}
        helperText={data?.component?.sHelper}


        // onKeyDown={handleFocus}
        // onFocus={handleFocus}
        onKeyDown={handleFocus}
        onFocus={handleFocus}

        onBlur={handleSelectionBlur}
        onSelect={handleSelectionChange}
        onKeyUp={handleSelectionChange}
        onMouseUp={handleSelectionChange}
        


        error={error && error[data?.component?.sName]}
        InputProps={
          data?.component?.sAdornPosition === "Start"
            ? {
                startAdornment: (
                  <>
                    {data?.component?.sAdornPosition && data?.component?.sAdornType === "Icon" && (
                      <InputAdornment position={data?.component?.sAdornPosition}>
                        <Icon iconName={data?.component?.sIcon?.slice(0, -4)} />
                      </InputAdornment>
                    )}
                    {data?.component?.sAdornPosition && data?.component?.sAdornType === "Text" && (
                      <InputAdornment position={data?.component?.sAdornPosition}>
                        {data?.component?.sIcon}
                      </InputAdornment>
                    )}
                  </>
                ),
                ...data?.component?.sProps
              }
            : {
                endAdornment: (
                  <>
                    {data?.component?.sAdornPosition && data?.component?.sAdornType === "Icon" && (
                      <InputAdornment position={data?.component?.sAdornPosition}>
                        <Icon iconName={data?.component?.sIcon.slice(0, -4)} />
                      </InputAdornment>
                    )}
                    {data?.component?.sAdornPosition && data?.component?.sAdornType === "Text" && (
                      <InputAdornment position={data?.component?.sAdornPosition}>
                        {data?.component?.sIcon}
                      </InputAdornment>
                    )}
                  </>
                ),
                ...data?.component?.sProps
              }
        }
        // {...data?.component?.sProps}
        
      />
      {/* </div> */}
    </Grid>
  );
}

export default NUMBER;
