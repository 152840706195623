import React from "react";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import { Link } from "react-router-dom";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { Box, Grid, Typography } from "@mui/material";
import { useLocation } from "react-router-dom";
import { Global_Data } from "../../globalData/GlobalData";
function parseData(input) {
  try {
    if (!input) {
      // Handle empty input
      return {};
    }

    if (typeof input === 'string') {
      // Try to parse if the input is a string
      return JSON.parse(input);
    }

    if (typeof input === 'object') {
      // Return the input if it is already an object
      return input;
    }

    // Handle unexpected input types
    throw new Error('Invalid input type');
  } catch (error) {
    // Handle JSON parsing errors or any other errors
    console.error('Error parsing input:', error);
    return {};
  }
}
function TitleHeader({ title, formData, style, item }) {
  const { token, globalvariables ,overlaySplit,sideBarStyle,isDialogDrawerOpen} = Global_Data();
  const location = useLocation();
  const items = formData?.form?.sBreadCrumbs?.title
    ? [
        { label: "Home", url: "/" },
        { label: formData?.form?.sBreadCrumbs?.title, url: formData?.form?.sBreadCrumbs?.path }
      ]
    : [{ label: formData?.form?.sBreadCrumbs?.title, url: formData?.form?.sBreadCrumbs?.path }];

    function removeKeysFromObject(obj, keysToRemove) {
      let newObj = { ...obj };
      keysToRemove.forEach(key => {
          delete newObj[key];
      });
      return newObj;
  }
  //   console.log(formData.form.sBreadCrumbs,'formData');
  return (
    <>


      <Box id= {overlaySplit?"overlay-title":"mainform-title"}
        sx={isDialogDrawerOpen ? removeKeysFromObject(sideBarStyle[overlaySplit?"overlay-title":"mainform-title"], ['backgroundColor', 'opacity', 'zIndex'])  : {...sideBarStyle[overlaySplit?"overlay-title":"mainform-title"],position:"sticky",top:'60px'}}
      // sx={{...sideBarStyle["mainform-title"],zIndex: isDialogDrawerOpen ? 99 : null }}
        style={{marginLeft: '0px'}}  
      >
        {/* {JSON.stringify(sideBarStyle['mainform-title'])} */}
        <Grid Container>
          <Breadcrumbs separator={<NavigateNextIcon /> } {...formData?.form?.sBreadCrumbs?.sProps} aria-label="breadcrumb" >
            {items?.map((item, index) => (
              <Link key={index} to={item.url} >
                {item.label}
              </Link>
            ))}
          </Breadcrumbs>
          <Grid container>
            <Grid item >
              <Typography
                {...title?.sProps}
                {...(location.search === "?formname=inventoryAdd" ? title?.sProps : style)}
                id={formData?.form?.sFormName+'-title'}
              >
                {/* {JSON.stringify(title?.sProps )}  */}
                {/* <Typography {location.search == "?formname=inventoryAdd" && ...style} {...title}   component="p"> */}
                {typeof title === "object" && title.hasOwnProperty("title") ? title.title : title}
                {/* {JSON.stringify(formData.form.sFormName+'-title')} */}
              </Typography>
            </Grid>
            <Grid item >
              <Typography variant="body1" component="p"></Typography>
            </Grid>
          </Grid>
        </Grid>
      </Box>


    </>
  );
}

export default TitleHeader;
