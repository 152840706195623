import React, { useEffect } from "react";
import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import Checkbox from "@mui/material/Checkbox";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import axios from "axios";

import { baseURL } from "../../api";
import { Paper } from "@mui/material";
import { Global_Data } from "../../globalData/GlobalData";
import jsPDF from "jspdf";
import { vsprintf } from "sprintf-js";
import { serverAddress } from "../../config";

function not(a, b) {
  return a.filter(value => b.indexOf(value) === -1);
}

function intersection(a, b) {
  return a.filter(value => b.indexOf(value) !== -1);
}

function union(a, b) {
  return [...a, ...not(b, a)];
}

export default function TransferListMain({ url, data }) {
  const { token, textValue,setTextValue } = Global_Data();
  const [checked, setChecked] = React.useState([]);
  const [left, setLeft] = React.useState([]);
  const [right, setRight] = React.useState([]);

  const fetchLeftSideData = async () => {
    try {
      // Generate the dynamic URI
      let dynamicUri = generateDynamicUri(data.component.content.sChoiceSource, textValue);
      // Await the axios request
      const result = await axios.get(`${baseURL}/${dynamicUri}`, {
        headers: {
          Authorization: `Bearer ${token}`
          // Other headers if needed
        }
      });
      setLeft(result.data.data.records);
      //   alert(JSON.stringify(result.data.data.records));
    } catch (error) {
      // Catch and log any errors
      console.log(error);
    }
  };

  const fetchRightSideData = async () => {
    try {
      // Generate the dynamic URI
      let dynamicUri = generateDynamicUri(data.component.content.sChosenSource, textValue);
      // Await the axios request
      const result = await axios.get(`${baseURL}/${dynamicUri}`, {
        headers: {
          Authorization: `Bearer ${token}`
          // Other headers if needed
        }
      });
      setRight(result.data.data.records);
      dummay(result.data.data.records)
      //   alert(JSON.stringify(result.data.data.records));
    } catch (error) {
      // Catch and log any errors
      console.log(error);
    }
  };

  useEffect(() => {
    fetchRightSideData();
    fetchLeftSideData();
  }, [textValue[data.component.content.sDataAware.replace("{", "").replace("}", "")]]);

  useEffect(() => {
    axios
      .get(`${baseURL}${url}`, {
        headers: {
          Authorization: `Bearer ${token}`
          // Other headers if needed
        }
      })
      .then(result => {
        setLeft(result?.data?.data.map(item => item.display));
      })

      .catch(error => {
        console.log(error);
      });
  }, []);

  const leftChecked = intersection(checked, left);
  const rightChecked = intersection(checked, right);

  const handleToggle = value => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const numberOfChecked = items => intersection(checked, items)?.length;

  const handleToggleAll = items => () => {
    if (numberOfChecked(items) === items.length) {
      setChecked(not(checked, items));
    } else {
      setChecked(union(checked, items));
    }
  };

  const handleCheckedRight = () => {
    const result = convertDynamicFieldsFromString(leftChecked, data.component.content.sValueField);
    setRight(right.concat(leftChecked));
    setLeft(not(left, leftChecked));
    setChecked(not(checked, leftChecked));
    // alert(JSON.stringify(vsprintf(
    //     data.component.content.sValueFormat,
    //     result[0]
    // )))

    // postDataToBackend(serverAddress + data.data.sDataDestination, {
    //   [data.component.sName]: vsprintf(data.component.content.sValueFormat, result[0])
    // });
    dummay( leftChecked);
  };

  const handleCheckedLeft = () => {
    setLeft(left.concat(rightChecked));
    setRight(not(right, rightChecked));
    setChecked(not(checked, rightChecked));
  };
  const handleAllRight = () => {
    setRight(right.concat(left));
    setLeft([]);
    dummay(left)
  };
  const handleAllLeft = () => {
    setLeft(left.concat(right));
    setRight([]);
  };

  function generateDynamicUri(template, data) {
    return template.replace(/{(\w+)}/g, (match, key) => {
      return data[key] !== undefined ? data[key] : match;
    });
  }
  function isNumeric(value) {
    return !isNaN(value) && !isNaN(parseFloat(value));
  }

  function convertDynamicFieldsFromString(data, keyString) {
    // Step 1: Convert the key string to an array of keys
    const keys = keyString
      .replace(/{|}/g, "")
      .split(",")
      .filter(key => key);

    // Step 2: Map through the data and extract values based on the keys
    return data.map(item => {
      return keys.map(key => {
        const value = item[key];
        // Automatically detect if the value is numeric and convert it
        return isNumeric(value) ? Number(value) : value;
      });
    });
  }

  //   function convertDynamicFieldsFromString(data, keyString) {
  //     // Step 1: Convert the key string to an array of keys
  //     const keys = keyString.replace(/{|}/g, "").split(',').filter(key => key);

  //     // Step 2: Map through the data and extract values based on the keys
  //     return data.map(item => keys.map(key => item[key]));
  //   }

  const postDataToBackend = async (url, data) => {
    try {
      const response = await axios.post(url, data, {
        headers: {
          Authorization: `Bearer ${token}` // Add token in the Authorization header
        }
      });
      alert(JSON.stringify(response.data));
      //    response.data; // Return response data
    } catch (error) {
      console.error(
        "Error making POST request:",
        error.response ? error.response.data : error.message
      );
    }
  };

  const dummay = (data1) => {
    // alert(JSON.stringify(data));
    
    const result = convertDynamicFieldsFromString([...right,...data1], data.component.content.sValueField);
    let dummyData = "";
    for (let i = 0; i < result.length; i++) {
      if (i == result.length - 1) {
        dummyData += vsprintf(data.component.content.sValueFormat, result[i]);
      } else {
        dummyData += vsprintf(data.component.content.sValueFormat, result[i]) + ";";
      }
    }
    const actualData = `[${dummyData}]`;
    setTextValue((pre)=>({...pre,[data.component.sName]:actualData}))
    // alert(JSON.stringify(actualData));
  };
  
  const customList = (title, items) => (
    <Card> 
      <CardHeader
        sx={{ px: 2, py: 1 }}
        avatar={
          <Checkbox
            onClick={handleToggleAll(items)}
            checked={numberOfChecked(items) === items.length && items.length !== 0}
            indeterminate={numberOfChecked(items) !== items.length && numberOfChecked(items) !== 0}
            disabled={items.length === 0}
            inputProps={{
              "aria-label": "all items selected"
            }}
          />
        }
        title={title}
        subheader={`${numberOfChecked(items)}/${items.length} selected`}
      />
      <Divider />
      <List
        sx={{
          width: 200,
          height: 230,
          bgcolor: "background.paper",
          overflow: "auto"
        }}
        dense
        component="div"
        role="list"
      >
        {items?.map(value => {
          const labelId = `transfer-list-all-item-${value}-label`;
          return (
            <ListItem key={value} role="listitem" button onClick={handleToggle(value)}>
              <ListItemIcon>
                <Checkbox
                  checked={checked.indexOf(value) !== -1}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{
                    "aria-labelledby": labelId
                  }}
                />
              </ListItemIcon>
              <ListItemText id={labelId} primary={vsprintf(
                  data.component.content.sDisplayFormat,
                  generateDynamicUri(data.component.content.sDisplayField, value)
                    ?.replace(/[{}]/g, "")
                    .split(",")
                )} />
            </ListItem>
          );
        })}
      </List>
    </Card>
  );
  const customList1 = items => (
    <Paper sx={{ width: 400, height: 230, overflow: "auto" }}>
      <List dense component="div" role="list">
        {items?.map(value => {
          const labelId = `transfer-list-item-${value}-label`;

          return (
            <ListItem key={value} role="listitem" button onClick={handleToggle(value)}>
              <ListItemIcon>
                <Checkbox
                  checked={checked.indexOf(value) !== -1}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{
                    "aria-labelledby": labelId
                  }}
                />
              </ListItemIcon>
              <ListItemText
                id={labelId}
                primary={vsprintf(
                  data.component.content.sDisplayFormat,
                  generateDynamicUri(data.component.content.sDisplayField, value)
                    ?.replace(/[{}]/g, "")
                    .split(",")
                )}
              />
            </ListItem>
          );
        })}
      </List>
    </Paper>
  );

  return (
    <>
      {data?.component?.options?.mode === "ENHANCE" ? (
        <Grid container spacing={2} justifyContent="center" alignItems="center">
          <Grid item>{customList("Choices", left)}</Grid>
          <Grid item>
            <Grid container direction="column" alignItems="center">
              <Button
                sx={{ my: 0.5 }}
                variant="outlined"
                size="small"
                onClick={handleCheckedRight}
                disabled={leftChecked.length === 0}
                aria-label="move selected right"
              >
                &gt;
              </Button>
              <Button
                sx={{ my: 0.5 }}
                variant="outlined"
                size="small"
                onClick={handleCheckedLeft}
                disabled={rightChecked.length === 0}
                aria-label="move selected left"
              >
                &lt;
              </Button>
            </Grid>
          </Grid>
          <Grid item>{customList("Chosen", right)}</Grid>
        </Grid>
      ) : (
        <Grid container spacing={2} justifyContent="center" alignItems="center">
          <Grid item>{customList1(left)}</Grid>
          <Grid item>
            <Grid container direction="column" alignItems="center">
              <Button
                sx={{ my: 0.5 }}
                variant="outlined"
                size="small"
                onClick={handleAllRight}
                disabled={left.length === 0}
                aria-label="move all right"
              >
                ≫
              </Button>
              <Button
                sx={{ my: 0.5 }}
                variant="outlined"
                size="small"
                onClick={handleCheckedRight}
                disabled={leftChecked.length === 0}
                aria-label="move selected right"
              >
                &gt;
              </Button>
              <Button
                sx={{ my: 0.5 }}
                variant="outlined"
                size="small"
                onClick={handleCheckedLeft}
                disabled={rightChecked.length === 0}
                aria-label="move selected left"
              >
                &lt;
              </Button>
              <Button
                sx={{ my: 0.5 }}
                variant="outlined"
                size="small"
                onClick={handleAllLeft}
                disabled={right.length === 0}
                aria-label="move all left"
              >
                ≪
              </Button>
            </Grid>
          </Grid>
          <Grid item>{customList1(right)}</Grid>
        </Grid>
      )}
    </>
  );
}

/*

import React, { useEffect } from "react";
import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import Checkbox from "@mui/material/Checkbox";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";

export default function TransferListMain() {
  const initialLeft = [
    { id: "01", name: "rajneesh", class: "MCA" },
    { id: "02", name: "vishal", class: "MCA" },
    { id: "03", name: "vikas", class: "MCA" }
  ];
  
  const initialRight = [
    { id: "01", name: "brij", class: "MCA" },
    { id: "02", name: "sachin", class: "MCA" },
    { id: "03", name: "rajneesh", class: "MCA" }
  ];

  const [checked, setChecked] = React.useState([]);
  const [left, setLeft] = React.useState(initialLeft);
  const [right, setRight] = React.useState(initialRight);

  // Utility functions for handling transfer
  function not(a, b) {
    return a.filter(itemA => !b.some(itemB => itemA.id === itemB.id));
  }

  function intersection(a, b) {
    return a.filter(itemA => b.some(itemB => itemA.id === itemB.id));
  }

  function union(a, b) {
    return [...a, ...not(b, a)];
  }

  const leftChecked = intersection(checked, left);
  const rightChecked = intersection(checked, right);

  const handleToggle = value => () => {
    const currentIndex = checked.findIndex(item => item.id === value.id);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const numberOfChecked = items => intersection(checked, items).length;

  const handleToggleAll = items => () => {
    if (numberOfChecked(items) === items.length) {
      setChecked(not(checked, items));
    } else {
      setChecked(union(checked, items));
    }
  };

  const handleCheckedRight = () => {
    setRight(right.concat(leftChecked));
    setLeft(not(left, leftChecked));
    setChecked(not(checked, leftChecked));
  };

  const handleCheckedLeft = () => {
    setLeft(left.concat(rightChecked));
    setRight(not(right, rightChecked));
    setChecked(not(checked, rightChecked));
  };

  const handleAllRight = () => {
    setRight(right.concat(left));
    setLeft([]);
  };

  const handleAllLeft = () => {
    setLeft(left.concat(right));
    setRight([]);
  };

  const customList = (title, items) => (
    <Card>
      <CardHeader
        sx={{ px: 2, py: 1 }}
        avatar={
          <Checkbox
            onClick={handleToggleAll(items)}
            checked={numberOfChecked(items) === items.length && items.length !== 0}
            indeterminate={numberOfChecked(items) !== items.length && numberOfChecked(items) !== 0}
            disabled={items.length === 0}
            inputProps={{
              "aria-label": "all items selected"
            }}
          />
        }
        title={title}
        subheader={`${numberOfChecked(items)}/${items.length} selected`}
      />
      <Divider />
      <List
        sx={{
          width: 200,
          height: 230,
          bgcolor: "background.paper",
          overflow: "auto"
        }}
        dense
        component="div"
        role="list"
      >
        {items.map(value => {
          const labelId = `transfer-list-all-item-${value.id}-label`;
          return (
            <ListItem key={value.id} role="listitem" button onClick={handleToggle(value)}>
              <ListItemIcon>
                <Checkbox
                  checked={checked.some(item => item.id === value.id)}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{
                    "aria-labelledby": labelId
                  }}
                />
              </ListItemIcon>
              <ListItemText
                id={labelId}
                primary={`${value.name}`}
                secondary={`Class: ${value.class}`}
              />
            </ListItem>
          );
        })}
      </List>
    </Card>
  );

  return (
    <Grid container spacing={2} justifyContent="center" alignItems="center">
      <Grid item>{customList("Choices", left)}</Grid>
      <Grid item>
        <Grid container direction="column" alignItems="center">
          <Button
            sx={{ my: 0.5 }}
            variant="outlined"
            size="small"
            onClick={handleCheckedRight}
            disabled={leftChecked.length === 0}
            aria-label="move selected right"
          >
            &gt;
          </Button>
          <Button
            sx={{ my: 0.5 }}
            variant="outlined"
            size="small"
            onClick={handleCheckedLeft}
            disabled={rightChecked.length === 0}
            aria-label="move selected left"
          >
            &lt;
          </Button>
          <Button
            sx={{ my: 0.5 }}
            variant="outlined"
            size="small"
            onClick={handleAllRight}
            disabled={left.length === 0}
            aria-label="move all right"
          >
            ≫
          </Button>
          <Button
            sx={{ my: 0.5 }}
            variant="outlined"
            size="small"
            onClick={handleAllLeft}
            disabled={right.length === 0}
            aria-label="move all left"
          >
            ≪
          </Button>
        </Grid>
      </Grid>
      <Grid item>{customList("Chosen", right)}</Grid>
    </Grid>
  );
}

*/
