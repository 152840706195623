import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Radio,
  RadioGroup
} from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import CustomAlert from "../AlertComponent/Alert";
import { Global_Data } from "../../globalData/GlobalData";

const RadioBox = ({ url, data, handleRadio,textValue }) => {
  const [option, setOption] = useState([]);
  const [customError, setCustomError] = useState("");
  const {token}= Global_Data()
  const [value, setValue] = useState('');
  useEffect(() => {
    setValue(textValue[data?.component?.sName])
  }, [textValue[data?.component?.sName]]);

  useEffect(() => {
    if (url) {
      axios
        .get(url, {
          headers: {
            Authorization: `Bearer ${token}`,
            // Other headers if needed
          }
        })
        .then(result => {
          setOption(result?.data?.data.records);
        
        })
        .catch(() => {
          setCustomError("Something went Wrong");
        });
    }
  }, [url]);


  const handleChange = event => {
    setValue(event.target.value);
    handleRadio(event.target.value);
  };



  return (
    <>
   
      {customError !== "" && <CustomAlert severity="error" message={customError} />}
      <FormControl>
        <FormLabel
          id="demo-controlled-radio-buttons-group "
          // labelPlacement={data?.component?.sLabel}
        >
          {data?.component?.sLabel}
        </FormLabel>
        <RadioGroup
          {...data?.component?.sProps}
          aria-labelledby="demo-controlled-radio-buttons-group"
          name="controlled-radio-buttons-group"
          id={data?.component?.sName}
          value={value}
          onChange={handleChange}
        >
          {option?.length > 0 &&
            option?.map((opt, index) => {

              return(
                <React.Fragment key={index}>
                  <FormControlLabel
                    value={opt?.sValue}
                    control={<Radio {...data?.component?.sProps} />}
                    label={opt?.sChoice}
                    {...data?.component?.sProps}
                    {...data?.component?.options?.others1}
                  />
                </React.Fragment>
              )
            })}
        </RadioGroup>
        <FormHelperText>{data?.component?.sHelper}</FormHelperText>
      </FormControl>
    </>
  );
};

export default RadioBox;
