import React from "react";
import { serverAddress } from "../../config";
import { Avatar, Grid } from "@mui/material";
import { Icon } from "../../utils/MuiIcons/Icon";
function AvatarComponent({ data }) {
  return (
    <Grid item {...data.grid_props}>
      <Avatar
        {...data?.component?.sProps}
        {...(data?.data?.sDataSource && {
          alt: data?.data?.sAction,
          src: `${serverAddress}${data?.data?.sDataSource}`
        })}
      >
        {data?.component?.sDefaultValue}
        {data?.component?.sIcon && <Icon iconName={data?.component?.sIcon.slice(0, -4)} />}
      </Avatar>
    </Grid>
  );
}

export default AvatarComponent;
