
import { toast } from "react-toastify";

function CustomAlert(severity, message) {

  if (message && severity === "error") {
    return toast.error(message);
  }
  if (message && severity === "warning") {
    return toast.warning(message);
  }
  if (message && severity === "INFO") {
    return toast.info(message);
  }
  if (message && severity === "OK") {
    return toast.success(message);
  }
}

export default CustomAlert;
