import React from "react";
import { serverAddress } from "../../config";
import {
  Avatar,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemIcon,
  ListItemText
} from "@mui/material";
import { Icon } from "../../utils/MuiIcons/Icon";
function ListItemdata(format, data, listdata) {

  return (
    <Grid item {...data?.component?.grid_props}>
      <List>
        { listdata?.data ? listdata?.data.map((item, index) => {
            const matchingFormat =
              format && format?.find(format => format.sFieldValue === item.primary);
            const style = matchingFormat?.sFieldFormat
              ? JSON.parse(matchingFormat.sFieldFormat)
              : null;
            return (
              <ListItem
                key={index}
                secondaryAction={<IconButton edge="end" aria-label="delete"></IconButton>}
              >
                {item?.source && (
                  <ListItemAvatar>
                    <Avatar src={`${serverAddress}${item?.source}`}></Avatar>
                  </ListItemAvatar>
                )}
                {matchingFormat?.sFieldValue === item.primary &&
                data?.component?.options?.mode === "ICON" ? (
                  <ListItemIcon>
                    {matchingFormat?.sFieldValue === item.primary ? (
                      <Icon iconName={matchingFormat?.sStatusIcon?.slice(0, -4)} />
                    ) : null}
                  </ListItemIcon>
                ) : (
                  <></>
                )}
                {matchingFormat?.sFieldValue === item.primary &&
                data?.component?.options?.mode === "LISTAVATARICON" ? (
                  <ListItemAvatar>
                    <Avatar>
                      {matchingFormat?.sFieldValue === item.primary ? (
                        <Icon iconName={matchingFormat?.sStatusIcon?.slice(0, -4)} />
                      ) : null}
                    </Avatar>
                  </ListItemAvatar>
                ) : (
                  <></>
                )}
                <ListItemText
                  primary={item.primary}
                  secondary={item.secondary}
                  {...(matchingFormat?.sFieldValue === item.primary ? style : null)}
                />
              </ListItem>
            );
          })
        
        :""}
      </List>
    </Grid>
  );
}

export default ListItemdata;
