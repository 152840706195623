import {
  Box,
  Checkbox,
  FormControlLabel,
  Grid,
  Icon,
  InputAdornment,
  TextField
} from "@mui/material";
import React, { useEffect, useState } from "react";

function Counter({ data, ind, index, error, textValue, handleTextValue }) {
  const [checked, setChecked] = useState(false);
  useEffect(() => {
    data.component.options.sDefaultValue == "Yes" ? setChecked(true) : setChecked(false);
  }, []);

  // useEffect(() => {

  //   if ( !checked) {
  //     textValue[data.component.sName] = ''
  //   }

  // }, [textValue]);

  const handleCheckboxChange = event => {
    setChecked(event.target.checked);
    const e = {
      target: {
        value: "",
        name: data?.component?.sName
      }
    };
    const e2 = {
      target: {
        value: data.component.options.enabledValue,
        name: data?.component?.sName
      }
    };
    if (!event.target.checked) {
      handleTextValue(e, ind, data?.validation);
    } else {
      handleTextValue(e2, ind, data?.validation);
    }
  };

  return (
    <>
      <Grid item {...data?.grid_props}>
    
        <Box sx={{ display: "flex" }}>
          <TextField
            sx={{ width: "100%" }}
            {...data?.component?.sProps}
            id={data?.component?.sName}
            label={data?.component?.sLabel}
            placeholder={data?.component?.sPlaceHolder}
            helperText={error?.[data?.component?.sName]||data?.component?.sHelper}
            variant={data?.component?.sProps?.variant}
            type={data?.component?.sAdornType}
            value={
              textValue[data?.component?.sName] && textValue[data?.component?.sName]?.length > 0
                ? textValue[data?.component?.sName]
                : "" ||
                  data?.validation?.sType === "COUNTER" ||
                  data?.validation?.sType === "FIXVALUE"
                ? data?.component?.sDefaultValue
                : ""
            }
            name={data?.component?.sName}
            onChange={e => handleTextValue(e, ind, data?.validation)}
           
            disabled={data.component.options.mode === "DEFAULT" ? true : checked}
            error={error && error[data?.component?.sName]}
            InputProps={
              data?.component?.sAdornPosition === "start"
                ? {
                    startAdornment: (
                      <>
                        {data?.component?.sAdornPosition &&
                          data?.component?.sAdornType === "icon" && (
                            <InputAdornment position={data?.component?.sAdornPosition}>
                              <Icon iconName={data?.component?.sIcon?.slice(0, -4)} />
                            </InputAdornment>
                          )}
                        {data?.component?.sAdornPosition &&
                          data?.component?.sAdornType === "text" && (
                            <InputAdornment position={data?.component?.sAdornPosition}>
                              {data?.component?.sIcon}
                            </InputAdornment>
                          )}
                      </>
                    )
                  }
                : {
                    endAdornment: (
                      <>
                        {data?.component?.sAdornPosition &&
                          data?.component?.sAdornType === "icon" && (
                            <InputAdornment position={data?.component?.sAdornPosition}>
                              <Icon iconName={data?.component?.sIcon.slice(0, -4)} />
                            </InputAdornment>
                          )}
                        {data?.component?.sAdornPosition &&
                          data?.component?.sAdornType === "text" && (
                            <InputAdornment position={data?.component?.sAdornPosition}>
                              {data?.component?.sIcon}
                            </InputAdornment>
                          )}
                      </>
                    )
                  }
            }
            // {...data?.component?.sProps}
          />
          {data.component.options.mode === "TOGGLE" && (
            <FormControlLabel
              sx={{ marginLeft: 2 }}
              control={<Checkbox id={data?.component?.sName+'-checkbox'} checked={checked} onChange={handleCheckboxChange} />}
              label={data.component.options.sLabel}
              id={data?.component?.sName+'-label'}
            />
          )}
        </Box>
        {/* {error && <p style={{ color: "red" }}>{error && error[data?.component?.sName]}</p>} */}
      </Grid>
    </>
  );
}

export default Counter;
