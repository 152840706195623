import React, { useEffect, useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import DeleteIcon from "@mui/icons-material/Delete";
import Button from "@mui/material/Button";
import {
  Box,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography
} from "@mui/material";
import ControlPointIcon from "@mui/icons-material/ControlPoint";
import FolderDeleteIcon from "@mui/icons-material/FolderDelete";
import { Global_Data } from "../../globalData/GlobalData";
import { serverAddress } from "../../config";
import axios from "axios";
function MediaDialog({ imagesName, setImagesIdsFromMedia,open, setOpen }) {

  const { token } = Global_Data();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [files, setFiles] = useState([]);
  const [allImages, setAllImages] = useState([]);

  const [checkedIds, setCheckedIds] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);
  const [checkedFile, setCheckedFile] = useState(null);
  const [allGallery, setAllGallery] = useState([]);
  const [selectedGallery, setSelectedGallery] = useState("");

  const fetchAllGallery = () => {
    axios
      .get(serverAddress + "/image/retrieve/gallerylist", {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json"
        }
      })
      .then(response => setAllGallery(response?.data?.data?.gallery))
      .catch(error => console.log(error));
  };

  useEffect(() => {
    fetchAllGallery();
  }, []);
  // const images = Array(30).fill(false);
  const handleCheckboxChange = value => {
    if (selectedOption === value) {
      setSelectedOption(null);
    } else {
      setSelectedOption(value);
    }
  };

  // const classes = useStyles();

  const handleFileChange = event => {
    // const fileList = event.target.files; 
    const selectedFiles = Array.from(event?.target?.files);
    const upd = [];
    const updatedFiles = selectedFiles?.map(file => {
      if (Math.round((file?.size / 1024).toFixed(2)) <= 50 * 1024) {
        upd.push(file);
      }
    });
    setFiles([...files, ...upd]);
    uploadImages(upd);
  };

  const handleDrop = event => {
    event.preventDefault();
    const selectedFiles = Array?.from(event?.dataTransfer?.files);
    const upd = [];
    const updatedFiles = selectedFiles?.map(file => {
      if (Math.round((file?.size / 1024).toFixed(2)) <= 50 * 1024) {
        upd.push(file);
      }
    });
    setFiles([...files, ...upd]);
    uploadImages(upd);
  };

  const handleDragOver = event => {
    event.preventDefault();
    event.dataTransfer.dropEffect = "copy";
  };

  function uploadImages(images) {
    images?.map(async image => {
      try {
        setimg([]);
        const formData = new FormData();
        formData.append("file", image);
        const response = await axios.post(
          serverAddress + `/image/upload?filename=${image.name}&gallery=${selectedGallery}`,
          formData,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "multipart/form-data"
            }
          }
        );
       

        setSelectedOption(null);
        fetchImage();
      } catch (error) {
        console.error("Error:", error);

        // Handle error, show error message or retry logic
      }
    });
  }

  const [openDialog, setOpenDialog] = useState(false);

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };
  const [img, setimg] = useState([]);

  const findIndexByFileName = (array, sImageID) => {
    return array.findIndex(obj => obj.sImageID === sImageID);
  };

  async function fetchImage() {
    setimg([]);
    axios
      .get(serverAddress + "/image/retrieve/gallery?gallery=" + selectedGallery || "''", {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json"
        }
      })
      .then(response => {
        // alert(response.data.data.images.length)
        if (response.data.data.images.length == 0) {
          setimg([]);
        } else {
          GetImg(response.data.data.images);
        }
      })
      .catch(error => console.log(error));
  }


  useEffect(() => {
    setCheckedFile(null);
    setaltname("");
    setdescription("");
    setimg([]);
    fetchImage();
  }, [selectedGallery]);

  const GetImg = async images => {
    const requestOptions = {
      headers: {
        Authorization: `Bearer ${token}`
      }
    };
    const updImg = [];

    for (const [ind, image] of images.entries()) {
      try {
        const response = await fetch(
         serverAddress+ `/image/retrieve?imageid=${image.sImageID}`,
          requestOptions
        );
        const blob = await response.blob();
        const objectURL = URL.createObjectURL(blob);
        const index = findIndexByFileName(img, image?.sImageID);
        updImg.push({ ...image, index: ind, src: objectURL });
        // setimg(pre => [...pre, { ...image, index: ind, src: objectURL }]);
      } catch (error) {
        console.error("Error fetching image:", error);
        throw error; // Rethrow the error for handling elsewhere if needed
      }
    }

    // After all images are fetched and updated, set the state
    setimg(updImg);
  };

  async function imagesDelete() {
     checkedIds?.map( async(ids,ind) =>{
      try {
        const response = await axios.delete(
          serverAddress + "/image/remove?imageid=" + ids,
          {
            headers: {
              Authorization: `Bearer ${token}`
            }
          }
        );
        setSelectedOption(null);
        setimg([]);
        fetchImage();
      } catch (error) {
        console.error("Error:", error?.response ? error?.response?.data : error?.message);
        // throw error;
      }
     })
   
  }
  async function handleDeleteGallery() {
    try {
      const response = await axios.delete(
        serverAddress + "/image/remove/gallery?gallery=" + selectedGallery,
        {
          headers: {
            Authorization: `Bearer ${token}` // Include your token in the Authorization header
          }
        }
      );
      setimg([]);
      setSelectedGallery("");
      fetchAllGallery();
      // fetchImage();
    } catch (error) {
      console.error("Error:", error.response ? error?.response?.data : error?.message);
      // throw error;
    }
  }

  const [altname, setaltname] = useState("");
  const [description, setdescription] = useState("");

  async function updateImage() {
    try {
      const formData = new FormData();
      const response = await axios.post(
        serverAddress +
          `/image/info/edit?imageid=${checkedFile.sImageID}&filename=${checkedFile.sFileName}&ext=png&altname=${altname}&description=${description}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
   
      setimg([]);
      setaltname("");
      setdescription("");
      setSelectedOption(null);
      fetchImage();
    } catch (error) {
      console.error("Error:", error);

      // Handle error, show error message or retry logic
    }
  }

  function handleCheckedFile(file) {
    if (file?.sFileName == checkedFile?.sFileName) {
      setCheckedFile(null);
    } else {
      setCheckedFile(file);
    }
  }

  function handleCheckedIds(ids) {
    const isIds = checkedIds.includes(ids);
    if (isIds) {
      const updIds = checkedIds.filter(elm => elm != ids);
      setCheckedIds(updIds);
    } else {
      setCheckedIds(pre => [...pre, ids]);
    }
  }

  function returnSelectedImagesIds() {
    if (setImagesIdsFromMedia) {
      setImagesIdsFromMedia((pre)=>([...pre,{sName:imagesName,data:checkedIds}]))
    }
    setCheckedIds([])
    handleClose()
  }
  return (
    <div>
      <Dialog open={open} fullWidth maxWidth="md">
        {/* <DialogTitle>Large Dialog</DialogTitle> */}

        <DialogContent>
          <Grid container>
            <Grid
              item
              xs={12}
              style={{
                border: "1px solid #c5c7c5",
                padding: "20px",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center"
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "10px"
                }}
              >
                <Typography> Gallery: </Typography>{" "}
                <FormControl size="small" fullWidth>
                  {/* <InputLabel id="select-label">Select Option</InputLabel> */}
                  <Select
                    style={{ minWidth: "300px" }}
                    fullWidth
                    value={selectedGallery}
                    onChange={e => {
                      setimg([]);
                      setSelectedOption(null);
                      setSelectedGallery(e.target.value);
                    }}
                    labelId="select-label"
                    id="select"
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    {allGallery?.map(gallery => (
                      <MenuItem value={gallery.sGalleryName}>{gallery.sGalleryName}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <ControlPointIcon
                  onClick={handleOpenDialog}
                  sx={{ fontSize: "2.5rem", color: "cadetblue", cursor: "pointer" }}
                />{" "}
                <FolderDeleteIcon
                  onClick={() => handleDeleteGallery()}
                  sx={{ fontSize: "2.5rem", color: "#ea653e", cursor: "pointer" }}
                />
              </div>
              <div>
                {" "}
                <Button variant="contained" onClick={() => returnSelectedImagesIds()}>
                  Select Image
                </Button>{" "}
                <Button variant="outlined" onClick={handleClose}>
                  Cancel
                </Button>
              </div>
            </Grid>
            <Grid item xs={8} style={{ padding: "20px" }}>
              {selectedOption && (
                <div
                  style={{
                    border: "1px solid #c5c7c5",
                    padding: "5px",
                    display: "flex",
                    justifyContent: "end",
                    alignItems: "center"
                  }}
                >
                  <DeleteIcon onClick={() => imagesDelete()} sx={{ color: "red" }} />
                </div>
              )}

              <Grid container style={{ maxHeight: "380px", overflowY: "scroll" }}>
                {img?.map((file, index) => (
                  <Grid key={index} item xs={4} style={{ padding: "10px", cursor: "pointer" }}>
                    <div
                      onClick={() => {
                        handleCheckedIds(file.sImageID);
                        handleCheckboxChange(index + 1);
                        handleCheckedFile(file);
                      }}
                      style={{ border: "1px solid #c5c7c5", height: "150px", position: "relative" }}
                    >
                      <img
                        key={index}
                        // src={URL.createObjectURL(file)}
                        src={img[index].src}
                        alt={`${file?.sAltText}`}
                        style={{ width: "150px", height: "150px" }}
                      />
                      <div style={{ position: "absolute", top: "5px", right: "5px" }}>
                        <label>
                          <input
                            type="checkbox"
                            checked={checkedIds.includes(file.sImageID)}
                            // checked={selectedOption === index + 1}
                            // onChange={() => handleCheckboxChange(index + 1)}
                          />
                        </label>
                      </div>
                    </div>{" "}
                  </Grid>
                ))}
              </Grid>
            </Grid>
            <Grid
              item
              xs={4}
              style={{ border: "1px solid #c5c7c5", margin: "20px 0", padding: "20px" }}
            >
              <Typography>File Name:</Typography>
              <Typography style={{ marginLeft: "30px" }}>{checkedFile?.sFileName}</Typography>
              <Typography>File Type:</Typography>
              <Typography style={{ marginLeft: "30px" }}>{checkedFile?.sFileType}</Typography>
              <Typography>Alt Text:</Typography>
              <TextField
                value={altname}
                onChange={e => setaltname(e.target.value)}
                fullWidth
                size="small"
              />
              <Typography>Description:</Typography>
              <div style={{ width: "", height: "100px" }}>
                <TextField
                  value={description}
                  onChange={e => setdescription(e.target.value)}
                  variant="outlined"
                  fullWidth
                  multiline
                  rows={3}
                />
              </div>
              <div
                style={{
                  marginTop: "20px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center"
                }}
              >
                <Button onClick={() => updateImage()} variant="outlined">
                  Update
                </Button>
              </div>
            </Grid>
            <Grid
              item
              xs={12}
              style={{ border: "1px solid #c5c7c5", padding: "17px " }}
              onDrop={handleDrop}
              onDragOver={handleDragOver}
            >
              <Grid container>
                <Grid item xs={6}>
                  <input
                    type="file"
                    id="file-input3"
                    multiple
                    accept="image/*"
                    style={{ display: "none" }}
                    onChange={handleFileChange}
                  />

                  <Button variant="contained">
                    <label htmlFor="file-input3">Upload Images</label>
                  </Button>

                  <Typography>File types jpg png gif Max file size 50Mb each</Typography>
                </Grid>
                <Grid item xs={6} alignSelf={"center"}>
                  Drag and Drop file here
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
        {/* <DialogActions>
          <Button  color="primary">
            Close
          </Button>
        </DialogActions> */}
        <MyDialog open={openDialog} fetchAllGallery={fetchAllGallery} onClose={handleCloseDialog} />
      </Dialog>
    </div>
  );
}

export default MediaDialog;

function MyDialog({ open, onClose, fetchAllGallery }) {
  const { token } = Global_Data();
  const [inputValue, setInputValue] = useState("");

  const handleInputChange = e => {
    setInputValue(e.target.value);
  };

  const handleSubmit = async () => {
    try {
      const response = await axios.post(
        serverAddress + "/image/add/gallery?gallery=" + inputValue,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json"
          }
        }
      );
      
      setInputValue("");
      fetchAllGallery();
      onClose();
    } catch (error) {
      console.error("Error:", error);

      // Handle error, show error message or retry logic
    }
  };

  const handleClose = () => {
    // Close the dialog without performing any action
    onClose();
  };

  return (
    <Dialog open={open}>
      <DialogTitle>Create Gallery</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          size="small"
          type="text"
          fullWidth
          value={inputValue}
          onChange={handleInputChange}
        />
      </DialogContent>
      <DialogActions>
        <Button size="small" onClick={handleSubmit} variant="contained">
          Submit
        </Button>
        <Button size="small" onClick={handleClose} variant="contained">
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}
