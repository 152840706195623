import * as MUIICon from "@mui/icons-material";
import * as React from "react";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Grow from "@mui/material/Grow";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import MenuItem from "@mui/material/MenuItem";
import MenuList from "@mui/material/MenuList";
import { Box, Grid, List, ListItem } from "@mui/material";
import { Global_Data } from "../../globalData/GlobalData";
import axios from "axios";
import { serverAddress } from "../../config";

const options = ["Create a merge commit", "Squash and merge", "Rebase and merge"];

export default function ButtonDropdown({ data, handleclickdata }) {
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);
  const [selectedIndex, setSelectedIndex] = React.useState(1);

  const handleClick = () => {
   // alert(JSON.stringify(data));
  };

  const handleMenuItemClick = (event, index) => {
    setSelectedIndex(index);
    setOpen(false);
  };

  const handleToggle = e => {
    // e.stopPropagation();
    setOpen(!open);
  };

  const handleClose = event => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };
  const [anchorEl1, setAnchorEl1] = React.useState(null);
  const open1 = Boolean(anchorEl1);
  const id = open1 ? "simple-popover" : undefined;

  const handleClick2 = event => {
    // event.stopPropagation();
    setAnchorEl1(event.currentTarget);
  };

  const handleClose2 = () => {
    setAnchorEl1(null);
  };

  let startIcon = null;
  let endIcon = null;
  const Icons = MUIICon[data?.component?.sIcon];

  if (data?.component?.sAdornPosition === "Start") {
    startIcon = <Icons aria-describedby={id} onClick={handleClick2} />;
  } else if (data?.component?.sAdornPosition === "End") {
    endIcon = <Icons aria-describedby={id} onClick={handleClick2} />;
  }

  const { token, mainFormData } = Global_Data();
  const [isRestricted, setRestricted] = React.useState(false);
  function fetchActivity() {
    const urlCapture =
      serverAddress +
      `/form/isallowed/transaction?module=${mainFormData.form.sFormName}&activity=${mainFormData.form.sFormAction}`;
    axios
      .get(urlCapture, {
        headers: {
          Authorization: `Bearer ${token}`
          // Other headers if needed
        }
      })
      .then(result => {
      
        if (result.data.data.Access == "No") {
          setRestricted(true);
        }
      })
      .catch(error => {
        console.error(error, "error456");
      });
  }

  React.useEffect(() => {
    fetchActivity();
  }, []);

  return (
    <Grid item>


      {data?.component?.sAdornPosition == "End" ? (
        <>
        {/* {JSON.stringify(data.grid_props)}
        ====
        {JSON.stringify(data.component.sProps)} */}
          <ButtonGroup  sx={data.component.sProps}  {...data.component.sProps} size="small" aria-label="Button group with a nested menu">
            <Button
              id={data?.component?.sName}
              onClick={e => handleclickdata(e, data?.component, data.data, data)}
              disabled={isRestricted}
              size="small"
              {...data.component.sProps}
              // startIcon={startIcon}
              // endIcon={endIcon}
            >
              
              {data.component.sLabel}
            </Button>
            <Button
              id={data?.component?.sName + "_icon"}
              onClick={handleToggle}
              size="small"
              ref={anchorRef}
              aria-controls={open ? "split-button-menu" : undefined}
              aria-expanded={open ? "true" : undefined}
              disabled={isRestricted}
              {...data.component.sProps}
            >
              <Icons { ...data?.component?.sIconProps} onClick={handleToggle} aria-describedby={id} />
            </Button>
          </ButtonGroup>
        </>
      ) : (
        <>
          <ButtonGroup sx={data.component.sProps}  {...data.component.sProps} variant="contained" size="small" aria-label="Button group with a nested menu">
            <Button
              onClick={handleToggle}
              size="small"
              id={data?.component?.sName + "_icon"}
              ref={anchorRef}
              aria-controls={open ? "split-button-menu" : undefined}
              aria-expanded={open ? "true" : undefined}
              disabled={isRestricted}
              {...data.component.sProps}
            >
              <Icons  { ...data?.component?.sIconProps}  onClick={handleToggle} aria-describedby={id} />
            </Button>
            <Button
              onClick={e => handleclickdata(e, data?.component, data.data, data)}
              disabled={isRestricted}
              id={data?.component?.sName}
              {...data.component.sProps}
              // startIcon={startIcon}
              // endIcon={endIcon}
            >
              {" "}
              {data.component.sLabel}
            </Button>
          </ButtonGroup>
        </>
      )}

      <Popper
        sx={{
          zIndex: 1
        }}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === "bottom" ? "center top" : "center bottom"
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <List>
                  {/* dwdwdw */}
                  {/* {JSON.stringify(data)} */}
                  {data?.child.map((item, ind) => (
                    <ListItem key={ind} onClick={() => setOpen((prev) => !prev)}>
                      <ChildDropDown handleclickdata={handleclickdata} item={item} dropDownSName={data?.component?.sName} />
                    </ListItem>
                  ))}
                </List>
                {/* <MenuList id="split-button-menu" autoFocusItem>
                  {options.map((option, index) => (
                    <MenuItem
                      key={option}
                      disabled={index === 2}
                      selected={index === selectedIndex}
                      onClick={(event) => handleMenuItemClick(event, index)}
                    >
                      {option}
                    </MenuItem>
                  ))}
                </MenuList> */}
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </Grid>
  );
} // import {Box, Button, Grid, List, ListItem, Popover } from "@mui/material";
// import React from "react";
// import * as MUIICon from "@mui/icons-material";

// function ButtonDropdown({ data, handleclickdata }) {
//   const [anchorEl1, setAnchorEl1] = React.useState(null);
//   const open1 = Boolean(anchorEl1);
//   const id = open1 ? "simple-popover" : undefined;

//   const handleClick2 = event => {
//     event.stopPropagation();
//     setAnchorEl1(event.currentTarget);
//   };

//   const handleClose2 = () => {
//     setAnchorEl1(null);
//   };

//   let startIcon = null;
//   let endIcon = null;
//   const Icons = MUIICon[data?.component?.sIcon];

//   if (data?.component?.sAdornPosition === "Start") {
//     startIcon = <Icons aria-describedby={id} onClick={handleClick2} />;
//   } else if (data?.component?.sAdornPosition === "End") {
//     endIcon = <Icons aria-describedby={id} onClick={handleClick2} />;
//   }

//   return (
//     <Grid item {...data?.grid_props}>
//       <Button
//         sx={{
//           border: "none",
//           "&:hover": {
//             border: "none"
//           }
//         }}
//         onClick={e => handleclickdata(e, data?.component, data.data, data)}
//         {...data.component.sProps}
//         startIcon={startIcon}
//         endIcon={endIcon}
//       >
//         {data.component.sLabel}
//       </Button>

//       <Popover
//         id={id}
//         open={open1}
//         anchorEl={anchorEl1}
//         onClose={handleClose2}
//         anchorOrigin={{
//           vertical: "bottom",
//           horizontal: "center"
//         }}
//         transformOrigin={{
//           vertical: "top",
//           horizontal: "center"
//         }}
//       >
//         <List>
//           {data?.child.map((item, ind) => (
//             <ListItem key={ind}>
//               <ChildDropDown handleclickdata={handleclickdata} item={item} />
//             </ListItem>
//           ))}
//         </List>
//       </Popover>
//     </Grid>
//   );
// }

// export default ButtonDropdown;

function ChildDropDown({ handleclickdata, item, dropDownSName }) {
  let Icons;
  if (item?.component?.sIcon) {
    Icons = MUIICon[item?.component?.sIcon];
  }

  return (
    <>
    {/* {JSON.stringify(item?.component.sIconProps)} */}
      {item.component.sType == "BUTTONLISTITEM" ? (
        <Box
          sx={{ cursor: "pointer", display: "flex", alignItems: "center", gap: "10px" }}
          onClick={e => {
            handleclickdata(e, item?.component, item.data, item);
          }}
          {...item?.component.sProps}
          id={dropDownSName ? dropDownSName + "_" + item?.component?.sName : item?.component?.sName}
        >
          {/* {JSON.stringify(item?.component.sAdornPosition)} */}
          {/* {item.component.sName} */}
          {item?.component.sAdornPosition == "Start" && item?.component?.sIcon && <Icons {...item?.component.sIconProps} />}
          {item.component.sLabel}
          {item?.component.sAdornPosition == "End" && item?.component?.sIcon && <Icons {...item?.component.sIconProps} />}
        </Box>
      ) : (
        item.component.sType == "BUTTONDROPDOWN" && (
          <ButtonDropdown data={item} handleclickdata={handleclickdata} />
        )
      )}
    </>
  );
}
