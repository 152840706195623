import React, { useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid
} from "@mui/material";
import { baseURL } from "../../api";
import axios from "axios";
import { Global_Data } from "../../globalData/GlobalData";

function DocumentSelect({
  data,
  renderComponent,
  selectedRowsDataGrid,
  setselectedRowsDataGrid,
  setdocumentSelectTableData,
  setdocumentSelectmappingData
}) {
  const [mainFormData, setMainFormData] = useState({});
  const [tableRecordData, settableRecordData] = useState([]);
  const { token, setTextValue, openDocumentSelect, setOpenDocumentSelect,multiDocumentSelectData,
    setMultiDocumentSelectData,setmultiDocumentSelectDataForTable } = Global_Data();
  const handleClickOpen = () => {
    setOpenDocumentSelect(true);
  };

  const handleClose = () => {
    setOpenDocumentSelect(false);
    setselectedRowsDataGrid([]);
  };
  const handleConfirm = () => {
    if (data?.component?.options?.submode == "MULTISELECT") {
      //  alert(JSON.stringify(multiDocumentSelectData))
    setmultiDocumentSelectDataForTable(multiDocumentSelectData)
    setOpenDocumentSelect(false);
    setMultiDocumentSelectData([])
    } else {
      
      //    const tableData = tableRecordData.filter(record => record.sInputTableName == 'pur_purchaseorderdetails' )
      const tableData = tableRecordData.filter(
        record =>
          record.sInputTableName ==
          selectedRowsDataGrid[0]?.[
            data?.data?.mapping?.tablerecord[0]?.tablenameSource?.replace(/[{}]/g, "")
          ]
      );
      // tableData[0]['mappingTableName'] = data?.data?.mapping?.tablerecord[0]?.tablenameDestination||''
      const newTable = tableData.map(record => ({
        ...record,
        mappingTableName: data?.data?.mapping?.tablerecord[0]?.tablenameDestination
      }));
      setdocumentSelectTableData(newTable);
      const obj = {};
      for (let i = 0; i < data?.data?.mapping?.tablerecord[0]?.mapping.length; i++) {
        const element = data?.data?.mapping?.tablerecord[0]?.mapping[i];
        obj[element.destinationColumn] = element.sourceXPath.split(".")[1];
      }
      setdocumentSelectmappingData(obj);
      //    alert(JSON.stringify(tabledetails));
      setTextValue(pre => ({
        ...pre,
        ...transformFields(data.data.mapping.mainrecord, mainFormData)
      }));

      setOpenDocumentSelect(false);
      setselectedRowsDataGrid([]);
    }
  };

  function replaceUriPlaceholders(data, uri) {
    return uri.replace(/{([^}]+)}/g, (match, key) => {
      return key in data ? data[key] : match;
    });
  }
  const fetchDataHandleDataGrid = async () => {
    try {
      const response = await axios.get(
        baseURL + replaceUriPlaceholders(selectedRowsDataGrid[0], data.data.sDataSource),
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );

      // setRows(true);
      //   alert(JSON.stringify(response?.data.data.mainrecord))

      setMainFormData(response?.data.data.mainrecord);
      settableRecordData(response?.data.data.tablerecords);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchDataHandleDataGrid();
  }, [selectedRowsDataGrid, data]);

  function transformFields(fields, mainBackend) {
    let result = {};

    fields.forEach(field => {
      const { sourceXPath, destinationComponent } = field;
      if (mainBackend.hasOwnProperty(sourceXPath)) {
        result[destinationComponent] = mainBackend[sourceXPath];
      }
    });

    return result;
  }

  return (
    <>
      {/* {replaceUriParams(selectedRowsDataGrid,data?.data?.mapping?.tablerecord[0]?.tablenameSource)} */}
      
      <Grid item {...data.component.sButton.grid_props}>
        <Button {...data.component.sButton.sButtonProps} onClick={handleClickOpen}>
          {data.component.sButton.sButtonLabel}
        </Button>
      </Grid>
      <Dialog open={openDocumentSelect}>
        {/* {JSON.stringify(selectedRowsDataGrid[0]?.[data?.data?.mapping?.tablerecord[0]?.tablenameSource?.replace(/[{}]/g, '')])} */}
        {/* {data?.data?.mapping?.tablerecord[0]?.tablenameSource?.replace(/[{}]/g, '')} */}
        {/* {data.data.sDataSource} */}
        {/* {JSON.stringify(selectedRowsDataGrid)} */}

        {/* { selectedRowsDataGrid[0]?.['sDocumentTable']}
   {JSON.stringify(selectedRowsDataGrid)}
   {data?.data?.mapping?.tablerecord[0]?.tablenameSource} */}
        {/* {JSON.stringify(data?.data?.mapping?.tablerecord[0]?.tablenameDestination)} */}
        <DialogTitle>{data.component.sDialog.sTitle}</DialogTitle>
        <DialogContent>
          {/* {JSON.stringify(tableRecordData)} */}

          {/* {JSON.stringify(data.data.mapping.tablerecord[0].tablenameSource)} */}
          {/* {JSON.stringify(data.data.mapping.tablerecord[0].mapping  )} */}

          {/* {JSON.stringify(data.sDialog)} */}
          {/* {JSON.stringify(selectedRowsDataGrid[0])} */}
          <DialogContentText>{renderComponent(data.child)}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={()=>handleConfirm()}
            {...data?.component?.sActionButton?.sConfirm.sButtonProps}
          >
            {data?.component?.sActionButton?.sConfirm?.sButtonLabel}
          </Button>
          <Button onClick={()=>handleClose()} {...data?.component?.sActionButton?.sCancel?.sButtonProps}>
            {data?.component?.sActionButton?.sCancel?.sButtonLabel}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default DocumentSelect;
