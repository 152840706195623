import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Global_Data } from "../../globalData/GlobalData";
import { FormControl, Grid, MenuItem, Select } from "@mui/material";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { serverAddress } from "../../config";

const Loadfrom = ({ data }) => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [optionsforLoadFrom, setOptionsforLoadFrom] = useState([]);
  const [firstforLoadFrom, setFirstforLoadFrom] = useState("");
  const [secondforLoadFrom, setSecondforLoadFrom] = useState("");

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const firstSelectBoxChange = e => {
    setFirstforLoadFrom(e.target.value);
  };

  const secondSelectBoxChange = e => {
    setSecondforLoadFrom(e.target.value);
  };

  const { token } = Global_Data();
  
  const btnnavigate = () => {
    handleClose();
    //   "/form/get/refer?to=purchaseInvoiceAdd&doc={docType}&ref={docNumber}"
    navigate(
      data?.component?.data?.sDataSource
        .replace("{docType}", firstforLoadFrom)
        .replace("{docNumber}", secondforLoadFrom)
    );
  };

  const getoptions = () => {
    axios
      .get(serverAddress + data?.component?.options?.docTypes[0].docNumberURL, {
        headers: {
          Authorization: "Bearer " + token
        }
      })
      .then(res => setOptionsforLoadFrom(res.data.data.records))
      .catch(err => console.log(err));
  };
  useEffect(() => {
    getoptions();
  }, []);

  return (
    <Grid item {...data?.component?.sProps?.grid_props}>
      <Button {...data?.component?.sProps?.buttonProps} onClick={handleClickOpen}>
        {data.component.sLabel}
      </Button>
      <Dialog open={open} fullWidth maxWidth={"sm"}>
        <DialogTitle> {data.component.sTitle}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Grid container>
              <Grid item xs={6} alignSelf={"center"}>
                Document Type
              </Grid>
              <Grid item xs={6}>
                <FormControl sx={{ m: 1 }} {...data?.component?.sProps?.selectProps}>
                  <Select
                    value={firstforLoadFrom}
                    size="small"
                    onChange={e => firstSelectBoxChange(e)}
                  >
                    <MenuItem value={data?.component?.options?.docTypes[0].docValue}>
                      {data?.component?.options?.docTypes[0].docValue}
                    </MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={6} alignSelf={"center"}>
                Purchase Order
              </Grid>
              <Grid item xs={6}>
                <FormControl sx={{ m: 1, minWidth: 120 }} {...data?.component?.sProps?.selectProps}>
                  <Select
                    value={secondforLoadFrom}
                    size="small"
                    onChange={e => secondSelectBoxChange(e)}
                  >
                    {optionsforLoadFrom.map((elm, ind) => (
                      <MenuItem value={elm[data?.component?.options?.docTypes[0].docNumberField]}>
                        {elm[data?.component?.options?.docTypes[0].docNumberField]}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => btnnavigate()} size="small" variant="contained">
            Proceed
          </Button>
          <Button onClick={handleClose} size="small" variant="contained">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
};

export default Loadfrom;
