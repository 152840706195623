import React, { useEffect, useState } from "react";
import { Box, Button, Grid, Typography } from "@mui/material";
import DeleteSharpIcon from "@mui/icons-material/DeleteSharp";
import * as MUIICon from "@mui/icons-material";
import { LinearProgressBar, PercentProgressBar } from "./AllPrograss";
import { serverAddress } from "../../config";
import axios from "axios";
import { Global_Data } from "../../globalData/GlobalData";
const DragAndDropArea = ({ type, editFiledata, data, setFilesForBackend }) => {
  const { token } = Global_Data();

  const [files, setFiles] = useState([]);
  const [id, setId] = useState(1);
  const [status, setStatus] = useState([]);
  const [isRotate, setisRotate] = React.useState(false);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const dropAreaStyle = {
    height: "50px",
    // border: "2px solid #ccc",
    // backgroundColor: "rgba(198, 217, 247,0.3",
    padding: "0 20px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    margin: 0
    // cursor: "pointer",
  };
  const containerStyle = {
    width: "600px",
    border: "2px solid #ccc"
  };

  const fileListStyle = {
    marginTop: "20px",
    padding: " 0  0 20px 0 "
  };
  function processFiles(filesArray) {
    return filesArray.map((file, i) => {
      return {
        ["file" + (i + 1)]: 100,
        fileName: file.sFileName,
        status: "success"
      };
    });
  }

  function getFileArray(files) {
    return files.map(file => ({
      file: {
        name: file.sFileName
      }
    }));
  }
  useEffect(() => {
    setFilesForBackend(uploadedFiles);
  }, [uploadedFiles]);
  useEffect(() => {
   
    if (type !== "ADD") {
      if (editFiledata && editFiledata.length > 0) {
        const result = getFileArray(editFiledata);
        setFiles(result);
        const processedFiles = processFiles(editFiledata);
        setStatus(processedFiles);
        setUploadedFiles(editFiledata);
        setFilesForBackend(editFiledata);
      }
    }
  }, [editFiledata]);
  const handleDrop = e => {
    e.preventDefault();
    const selectedFiles = Array.from(e.dataTransfer.files);
    const maxSize = data.component.options.maxFileSize; // 50MB in bytes
    const allowedTypes = ["application/pdf", "image/jpeg", "image/jpg"];
    const maxFiles = data.component.options.maxFiles;
    const mode = data.component.options.mode; // "Multiple"

    if (selectedFiles?.length <= +maxFiles && files?.length < +maxFiles) {
      const statusArray = [...status];
      const upd = [];
      selectedFiles.map(file => {
        if (Math.round((file?.size / 1024).toFixed(2)) <= maxSize * 1024) {
          upd.push({
            file,
            size: formatFileSize(file?.size)
          });
        }
      });

      setFiles(prevFiles => [...prevFiles, ...upd]);

      upd.map((file, i) => {
        statusArray.push({
          [`file${i + 1 + files?.length}`]: 92,
          status: "loading",
          fileName: file.file?.name
        });
        setStatus(pre => [
          ...pre,
          { [`file${i + 1 + files?.length}`]: 92, status: "loading", fileName: file.file?.name }
        ]);
      });
      if (upd.length > 0) {
        handleUploadTobackend(upd, statusArray);
      }
    }
  };

  const handleFileSelect = e => {
    e.preventDefault();
    const selectedFiles = Array.from(e.target?.files);
    const maxSize = data.component.options.maxFileSize; // 50MB in bytes
    const allowedTypes = ["application/pdf", "image/jpeg", "image/jpg"];
    const maxFiles = data.component.options.maxFiles;
    const mode = data.component.options.mode; // "Multiple"
    if (selectedFiles?.length <= +maxFiles && files?.length < +maxFiles) {
      const statusArray = [...status];
      const upd = [];
      const updatedFiles = selectedFiles.map(file => {
        if (Math.round((file?.size / 1024).toFixed(2)) <= maxSize * 1024) {
          upd.push({
            file,
            size: formatFileSize(file?.size)
          });
        }
      });
      setFiles(prevFiles => [...prevFiles, ...upd]);
      upd.map((file, i) => {
        statusArray.push({
          [`file${i + 1 + files?.length}`]: 92,
          status: "loading",
          fileName: file.file?.name
        });
        setStatus(pre => [
          ...pre,
          { [`file${i + 1 + files?.length}`]: 92, status: "loading", fileName: file.file?.name }
        ]);
      });
      if (upd.length > 0) {
        handleUploadTobackend(upd, statusArray);
      }
    }
  };

  const formatFileSize = bytes => {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB"];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + " " + sizes[i];
  };

  const deleteFiles = async (type, name) => {
  
    if (type == "errorDelete") {
      const updFiles = files.filter(file => file.file.name !== name);
      setFiles(updFiles);
    } else {
      const filtered = uploadedFiles.filter(obj => obj?.sFileName === name);
      const currentObj = filtered[filtered.length - 1];
      try {
        const response = await axios.delete(
          serverAddress + data.component.data.sDeleteURL + currentObj?.sFileID,
          {
            headers: {
              Authorization: `Bearer ${token}` // Include your token in the Authorization header
            }
          }
        );
        if (response.data.data.filecount == 1) {
          const updFiles = files.filter(file => file.file.name !== name);
          setFiles(updFiles);
          const filtered = uploadedFiles.filter(obj => {
            return obj.sFileName !== name;
          });
          setUploadedFiles(filtered);
        }
        return response.data;
      } catch (error) {
        console.error("Error:", error.response ? error.response.data : error.message);
        // throw error;
      }
    }
  };

  const DeleteIcons = MUIICon[data.component.options.sActionIcons.sIconDelete];
  const DownLoadIcons = MUIICon[data.component.options.sActionIcons.sIconDownload];
  const RetryIcons = MUIICon[data.component.options.sActionIcons.sIconRetry];
  const SuccessIcons = MUIICon[data.component.options.sActionIcons.sIconSuccess];

  function getObjectByFileName(fileName, arr) {
    const filtered = arr.filter(obj => {
      const key = Object.keys(obj)[2];
      return obj[key] === fileName;
    });
    return filtered.length > 0 ? filtered[filtered.length - 1] : null;
  }

  const handleUploadTobackend = (filedata, statusArray) => {
    let updStatusArr = JSON.parse(JSON.stringify(statusArray));
    function PushtoArry(obj, dataarray) {
      const key = Object.keys(obj)[0];
      const newDataArray = dataarray.map(item => ({ ...item }));
      const index = newDataArray.findIndex(item => item[key] !== undefined);
      if (index !== -1) {
        newDataArray[index] = { ...obj };
      }
      return newDataArray;
    }

    filedata?.map((file, i) => {
      const formData = new FormData();
      formData.append("file", file?.file);
      setTimeout(() => {
        axios
          .post(serverAddress + data.component.data.sUploadURL + file?.file?.name, formData, {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "multipart/form-data"
            }
          })
          .then(response => {
            const currentObject = getObjectByFileName(file.file.name, updStatusArr);
            const toFile = Object.keys(currentObject);
            const GetData = PushtoArry(
              { [toFile[0]]: 100, status: "success", fileName: file.file.name },
              updStatusArr
            );
            updStatusArr = GetData;
            setStatus(GetData);
            setUploadedFiles(pre => [...pre, { ...response.data.data }]);
          })
          .catch(error => {
            console.error("Error uploading files:", error);
            const currentObject = getObjectByFileName(file.file.name, updStatusArr);
            const toFile = Object.keys(currentObject);
            const GetData = PushtoArry(
              { [toFile[0]]: 92, status: "error", fileName: file.file.name },
              updStatusArr
            );
            updStatusArr = GetData;
            setStatus(GetData);
          });
      }, 0);
    });
  };

  function filterByKey(keyName, arrayData) {
    return arrayData.find(obj => obj[keyName] !== undefined);
  }

  const DownLoadFile = async name => {
    //data.component.data.sDownloadURL
  
    const filtered = uploadedFiles.filter(obj => {
      return obj.sFileName === name;
    });
    const currentObj = filtered[filtered.length - 1];
    const api = serverAddress + data.component.data.sDownloadURL + currentObj?.sFileID;
    const requestOptions = {
      headers: {
        Authorization: `Bearer ${token}`
      }
    };

    fetch(api, requestOptions)
      .then(response => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.blob();
      })
      .then(blob => {
        const downloadLink = document.createElement("a");
        downloadLink.href = URL.createObjectURL(blob);
        downloadLink.download = `${currentObj.sFileName}`;
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
        URL.revokeObjectURL(downloadLink.href);
      })
      .catch(error => {
        console.error("Error fetching image:", error);
      });
  };

  const acceptFiles = string => {
    const fileTypes = string.split(",");
    const modifiedFileTypes = fileTypes.map(type =>
      type.startsWith(".") ? type : `.${type.trim()}`
    );
    return modifiedFileTypes;
  };

  const retryUploadFile = async file => {
    handleReferesh();
    let updStatusArr = JSON.parse(JSON.stringify(status));

    function PushtoArry(obj, dataarray) {
      const key = Object.keys(obj)[0];
      // Perform a deep copy of the original array
      const newDataArray = dataarray.map(item => ({ ...item }));
      // Check if the key exists in any of the items in the array
      const index = newDataArray.findIndex(item => item[key] !== undefined);
      if (index !== -1) {
        // If the key exists, replace the item at that index with the new object
        newDataArray[index] = { ...obj };
      }
      return newDataArray;
    }
    const formData = new FormData();
    formData.append("file", file?.file);
    axios
      .post(serverAddress + data.component.data.sUploadURL + file?.file?.name, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data"
        }
      })
      .then(response => {
        const currentObject = getObjectByFileName(file.file.name, updStatusArr);
        const toFile = Object.keys(currentObject);
        const GetData = PushtoArry(
          { [toFile[0]]: 100, status: "success", fileName: file.file.name },
          updStatusArr
        );
        updStatusArr = GetData;
        setStatus(GetData);
        setUploadedFiles(pre => [...pre, { ...response.data.data }]);
      })
      .catch(error => {
        console.error("Error uploading files:", error);
        const currentObject = getObjectByFileName(file.file.name, updStatusArr);
        const toFile = Object.keys(currentObject);
        const GetData = PushtoArry(
          { [toFile[0]]: 92, status: "error", fileName: file.file.name },
          updStatusArr
        );
        updStatusArr = GetData;
        setStatus(GetData);
      });
  };
  const handleReferesh = () => {
    setisRotate(true);

    setTimeout(() => {
      setisRotate(false);
    }, 2000);
  };

  return (
    <Grid {...data.component.grid_props} style={containerStyle}>
      <Box {...data.component.sContainerProps} onDragOver={e => e.preventDefault()}>
        <Box style={dropAreaStyle} onDrop={handleDrop} onDragOver={e => e.preventDefault()}>
          <Button {...data.component.sSelectButton.sProps} htmlFor="fileElem">
            <label htmlFor="fileElem">{data.component.sSelectButton.sLabel}</label>
          </Button>
          <Typography
            {...data.component.sPrimaryLabel.sProps}
            sx={{ ...data.component.sPrimaryLabel.sProps.sx[0] }}
          >
            {data.component.sPrimaryLabel.sLabel}
          </Typography>
          <input
            type="file"
            id="fileElem"
            accept={acceptFiles(data.component.options.allowedExtensions)}
            multiple={data.component.options.mode === "Multiple" ? true : false}
            onChange={handleFileSelect}
            style={{ display: "none" }}
          />
        </Box>
        <Typography
          {...data.component.sSecondaryLabel.sProps}
          sx={{ ...data.component.sSecondaryLabel.sProps.sx[0] }}
        >
          {data.component.sSecondaryLabel.sLabel}
        </Typography>
      </Box>

      {files.length > 0 && (
        <Box style={fileListStyle}>
          {files.map(({ file, size }, index) => (
            <div style={{ padding: "0 10px" }}>
              <div
                key={index}
                style={{
                  margin: "12px 0",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center"
                }}
              >
                <div>
                  <Typography
                    {...data.component.sFileNameTypographyProps.sProps}
                    sx={data.component.sFileNameTypographyProps.sx[0]}
                  >
                    {file?.name}
                  </Typography>
                  <Typography
                    {...data.component.sFileNameTypographyProps.sProps}
                    sx={data.component.sFileNameTypographyProps.sx[0]}
                    style={{ fontSize: 15 }}
                  >
                    {size}
                  </Typography>
                </div>
                <div
                  style={{
                    gap: "5px",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center"
                  }}
                >
                  <PercentProgressBar
                  
                 status={filterByKey(`file${index + 1}`, status)}
                limit={filterByKey(`file${index + 1}`, status)?.[`file${index + 1}`]}
                   />

                  {filterByKey(`file${index + 1}`, status)?.status === "error" && (
                    <>
                      <RetryIcons
                        className={isRotate && "lovu1"}
                        style={{ cursor: "pointer" }}
                        onClick={() => retryUploadFile({ file: file, fileName: file.name })}
                      />
                      <DeleteIcons
                        style={{ cursor: "pointer" }}
                        onClick={() => deleteFiles("errorDelete", file?.name)}
                      />
                    </>
                  )}
                  {filterByKey(`file${index + 1}`, status)?.status === "success" && (
                    <>
                      <DownLoadIcons
                        style={{ cursor: "pointer" }}
                        onClick={() => DownLoadFile(file?.name)}
                      />
                      <DeleteIcons
                        style={{ cursor: "pointer" }}
                        onClick={() => deleteFiles("successDelete", file?.name)}
                      />
                    </>
                  )}
                  {/* {filterByKey(`file${index + 1}`, status)?.status === "success" && (
                      
                    )} */}
                </div>
              </div>
            
              <LinearProgressBar
                status={filterByKey(`file${index + 1}`, status)}
                limit={filterByKey(`file${index + 1}`, status)?.[`file${index + 1}`]}
              />
            </div>
          ))}
        </Box>
      )}
    </Grid>
  );
};

export default DragAndDropArea;
