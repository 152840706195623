import { Button, IconButton } from "@mui/material";
import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { Icon } from "../../utils/MuiIcons/Icon";
import * as MUIICon from "@mui/icons-material";
import axios from "axios";
import { Global_Data } from "../../globalData/GlobalData";
import { serverAddress } from "../../config";
import { memo } from "react";

const ButtonComponent = ({ data, onClickvalue, formName}) => {
  const { token, mainFormData, openModalChildData, openModalDataActive } = Global_Data();
  // alert(JSON.stringify(openModalDataActive.sFormName))
  // alert(JSON.stringify(openModalDataActive?.sFormName))
  const [isRestricted, setRestricted] = useState(false);

  function fetchActivity() {
    
    if (
      formName && data?.component.options.action
    ) {

      const urlCapture =
        serverAddress +
        `/form/isallowed/transaction?module=${
          formName
        }&activity=${data?.component.options.action}`;
      axios
        .get(urlCapture, {
          headers: {
            Authorization: `Bearer ${token}`
            // Other headers if needed
          }
        })
        .then(result => {
          if (result.data.data.Access == "No") {
            setRestricted(true);
          }
        })
        .catch(error => {
          console.error(error, "error456");
        });
        return
    }
   
  }

  // useEffect(() => {
  //   if (data?.component.options.action !== "CANCEL" && data?.component.options.action !== "CLOSE") {
  //     fetchActivity();
  //   }
  //   return ()=>{
  //     setRestricted(false)
  //   }
  // }, [mainFormData,openModalDataActive, data]);
  const timeoutRef = useRef(null);

  useEffect(() => {
    if (data?.component.options.action !== "CANCEL" && data?.component.options.action !== "CLOSE") {
      // Clear any existing timeout to reset the debounce period
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
      // Set a new timeout
      timeoutRef.current = setTimeout(() => {
        fetchActivity();

      }, 500); // Adjust the debounce delay (in milliseconds) as needed
    }
// alert(formName)
    return () => {
      setRestricted(false);

      // Clear the timeout if the component unmounts or dependencies change
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, [formName ]);



  if (data?.component?.options.mode === "DEFAULT" && data?.component?.sAdornType === "") {
    return (
      <>
    
        <Button
        id={data.component.sName}
                  onClick={onClickvalue}
          {...data?.component?.sProps}
          aria-label={data?.component?.sHelper}
          disabled={isRestricted}
        >
          {data?.component?.sLabel}
        </Button>
      </>
    );
  } else if (
    data?.component?.options.mode === "DEFAULT" &&
    data?.component?.sAdornType === "Icon"
  ) {
    const Icons = MUIICon[data?.component?.sIcon];
    let startIcon = null;
    let endIcon = null;
    if (data?.component?.sAdornPosition === "Start") {
      startIcon = <Icons />;
    } else if (data?.component?.sAdornPosition === "End") {
      endIcon = <Icons />;
    }

    return (
      <>
     
        <Button
         id={data.component.sName}
          onClick={onClickvalue}
          {...data?.component?.sProps}
          aria-label={data?.component?.sHelper}
          startIcon={startIcon}
          endIcon={endIcon}
          disabled={isRestricted}
        >
          {data?.component?.sLabel}
        </Button>
      </>
    );
  } else if (
    data?.component?.options.mode === "ICONBUTTON" &&
    data?.component?.sAdornType === "Icon"
  ) {
    return (
      <>
        <IconButton
          onClick={onClickvalue}
          {...data?.component?.sProps}
          disabled={isRestricted}
          aria-label={data?.component?.sHelper}
        >
          <Icon sProps={data?.component?.sIconProps} iconName={data?.component?.sIcon} />
        </IconButton>
      </>
    );
  } else {
    return 'null';
  }
};

export default memo(ButtonComponent);
