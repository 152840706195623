import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import { Typography } from "@mui/material";
import WarningIcon from "@mui/icons-material/Warning";
function CustomDialog({ open, setClose }) {
  return (
    <Dialog open={open.open}>
      <div style={{ display: "flex", justifyContent: "center", marginTop: "30px" }}>
        <WarningIcon sx={{ fontSize: "50px", color: "red" }} />
      </div>
      <Typography sx={{ margin: "15px 15px" }}>{open.msg}</Typography>
      <DialogActions>
        <Button onClick={() => setClose(false)} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default CustomDialog;
