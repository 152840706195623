import React from "react";
import { memo } from "react";
import isEqual from "lodash/isEqual";
import { Box } from "@mui/material";
import { Global_Data } from "../../globalData/GlobalData";

function FormFooter({ formData, renderComponent }) {
  const { sideBarStyle } = Global_Data();
  

  return (
    formData && (
      <Box id="footer-menu"
        sx={sideBarStyle['footer-menu']}
      >
        {renderComponent(formData)}
      </Box>
    )
  );
}

// Custom comparison function
function areEqual(prevProps, nextProps) {
  console.log("Comparing props:");
  console.log("Prev formData.footer:", prevProps.formData?.footer);
  console.log("Next formData.footer:", nextProps.formData?.footer);
  console.log("Prev renderComponent:", prevProps.renderComponent);
  console.log("Next renderComponent:", nextProps.renderComponent);

  if (!isEqual(prevProps.formData?.footer, nextProps.formData?.footer)) {
    return false;
  }
  if (prevProps.renderComponent !== nextProps.renderComponent) {
    return false;
  }
  return true;
}

export default memo(FormFooter, areEqual);
