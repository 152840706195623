import { BarChart } from '@mui/x-charts';
import axios from 'axios';
import React, { useEffect, useMemo, useState } from 'react'
import { serverAddress } from '../../config';
import { Global_Data } from '../../globalData/GlobalData';
import { vsprintf } from 'sprintf-js';

const RenderBarchart = ({data}) => {
    const [barchartDataResponse, setBarchartDataResponse] = useState();
    const { token, textValue } = Global_Data?.() || {};

    function replacePlaceholders(uri, data) {
        const regex = /{([^}]+)}/g;
        const replacedUri = uri?.replace(regex, (match, key) => {
            return key in (data || {}) ? data[key] : match;
        });
        return replacedUri;
    }

    const dependencyFields = useMemo(() => {
        return data?.data?.sDataAware.match(/{(.*?)}/g)?.map((field) => field.replace(/[{}]/g, "")) || [];
    }, [data?.data?.sDataAware]);

    const dependencies = useMemo(() => {
        return dependencyFields.map((field) => textValue?.[field] ?? null);
    }, [textValue, dependencyFields]);

    useEffect(() => {
        if(!data?.data?.bCascade){
            axios.get(`${serverAddress}${data?.data?.sDatasetSource}`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }).then(response => {
                setBarchartDataResponse(response);
            }).catch(() => {
                // setGlobalError(error);
            });
        } else {
            let processedURL = replacePlaceholders(data?.data?.sDatasetSource, textValue);
            axios.get(`${serverAddress}${processedURL}`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }).then(response => {
                setBarchartDataResponse(response);
            }).catch(() => {
                // setGlobalError(error);
            });
        }
    }, [data?.data?.sDatasetSource, dependencies]);

    function addLabels(series, formatting) {
        return series.map((item) => ({
          ...item,
          label: data?.component?.sLabels[item.dataKey],
          valueFormatter: (v) => {
            try {
                const match = formatting.match(/^([^\s%]*)/);
                const prefix = match ? match[1] : '';
                const formatWithoutPrefix = formatting.replace(prefix, '').trim();
                const formattedValue = vsprintf(String(v), formatWithoutPrefix);
                return `${prefix} ${Number(formattedValue).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`;
            } catch (error) {
                console.error('Formatting error:', error, 'Value:', v, 'Formatting:', formatting);
            }
          },
        }));
    }

    return (
        <>
            {barchartDataResponse && 
                <BarChart   
                    id={data?.component?.sName}
                    dataset={barchartDataResponse?.data?.data?.records}
                    series={addLabels(data?.component?.sSeries, data?.component?.sFormatting)}
                    xAxis={[{ ...data?.component?.sXAxis }]}
                    yAxis={[{ ...data?.component?.sYAxis }]}
                    layout={data?.component?.sProps?.layout}
                    grid={data?.component?.sProps?.grid}
                    borderRadius={data?.component?.sProps?.borderRadius}
                    barLabel={data?.component?.sProps?.barLabel}
                    width={data?.component?.sProps?.width}
                    height={data?.component?.sProps?.height}
                    slotProps={data?.component?.sSlotProps}
                    margin={{ left: 70 }}
                />
            }
        </>
    )
}

export default RenderBarchart;