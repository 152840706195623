import * as React from "react";
import PropTypes from "prop-types";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

function LinearProgressWithLabel(props) {
  return (
    <Box sx={{ display: "flex",alignItems: "center" }}>
      <Box sx={{ width: "100%" }}>
       
        <div style={{ position: "relative" }}>
          <div
            className="green-bar"
            style={{ background: "rgb(178, 193, 198)", height: "5px", width: "100%" }}
          ></div>
        
          <div
            className="green-bar"
            style={{
              background: props?.status?.status == 'error' ? "red" : props?.status?.status == 'success' ?"rgb(46, 195, 51)": "#1565C0",
              position: "absolute",
              top: 0,
             
              height: "6px",
              transition: "width 0.1s ease-out",
              width: props.value + "%"
            }}
          ></div>
        </div>

        {/* <LinearProgress variant="buffer" {...props} /> */}
      </Box>
    </Box>
  );
}

LinearProgressWithLabel.propTypes = {
  value: PropTypes.number.isRequired
};

export function LinearProgressBar({limit, status }) {

  const [progress, setProgress] = React.useState(0);

  React.useEffect(() => {
    const timer = setInterval(
      () => {
        setProgress(prevProgress => (prevProgress >= limit ? limit : prevProgress + 1));
      },
      limit === 100 ? 0 : 100
    );
    return () => {
      clearInterval(timer);
    };
  }, [limit]);

  return (
    <Box sx={{ width: "100%" }}>
      <LinearProgressWithLabel status={status} value={progress} />
    </Box>
  );
}
export function PercentProgressBar({ limit }) {
  const [progress, setProgress] = React.useState(0);

  React.useEffect(() => {
    const timer = setInterval(
      () => {
        setProgress(prevProgress => (prevProgress >= limit ? limit : prevProgress + 1));
      },
      limit === 100 ? 0 : 100
    );
    return () => {
      clearInterval(timer);
    };
  }, [limit]);

  return (
    <Box sx={{ width: "100%" }}>
      <Typography variant="body2">{`${Math.round(progress)}%`}</Typography>
    </Box>
  );
}
