import { Accordion, AccordionDetails, AccordionSummary, Grid, Typography } from "@mui/material";
import React from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import VarValue from "./VarValue";


function AccordianComponent({data,renderComponent,textValue}) {
  return (
    <>
      <Grid item {...data?.grid_props}>
        <Accordion {...data?.component?.sProps}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            {/* <Typography></Typography> */}
            <Typography sx={{ width: '33%', flexShrink: 0 }}>
              {data?.component?.sTitle}
            </Typography>
            {renderComponent(data?.component?.sSummary)}
          </AccordionSummary>
          <AccordionDetails>{renderComponent(data.child)}</AccordionDetails>
        </Accordion>
      </Grid>
    </>
  );
}

export default AccordianComponent;
