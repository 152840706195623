import { Box, Grid } from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Global_Data } from "../../globalData/GlobalData";
import { serverAddress } from "../../config";
import PdfViewer from "./PDFViewer";


function CUSTOMBOX({ data }) {
  const { token } = Global_Data();
  const [htmlData, setHtmlData] = useState("");
  const location = useLocation();

  const searchParams = new URLSearchParams(location.search);
  let id = searchParams.get("id");

  function replacePlaceholder(template, value) {
    return template.replace(/{.*?}/, value);
  }

  let uri = replacePlaceholder(data.data.sDataSource, id);
  async function fetchData() {
    axios
      .get(serverAddress + uri, {
        headers: {
          Authorization: `Bearer ${token}`
          // Other headers if needed
        }
      })
      .then(result => {
        setHtmlData(result.data);
      })
      .catch(error => {
        // setGlobalError(error);
        console.log(error, "error");
      });
  }
  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Grid item id={data.component.sName} {...data.grid_props}>
      {data.data.sAction == "HTML" && <Box dangerouslySetInnerHTML={{ __html: htmlData }} />}
      {data.data.sAction == "PDF" && <PdfViewer uri={uri}/>}

    </Grid>
  );
}

export default CUSTOMBOX;
