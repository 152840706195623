import { useEffect, useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import { Global_Data } from "../../globalData/GlobalData";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import "react-pdf/dist/esm/Page/TextLayer.css";
import { serverAddress } from "../../config";

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.js`;

function PdfViewer({uri}) {
  const { token } = Global_Data();
  const [numPages, setNumPages] = useState(null);
  const [fileUrl, setFileUrl] = useState(null);

  function onLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  let options = {
    method: "GET",
    headers: {
      Accept: "*/*",
      Authorization: `Bearer ${token}`
    }
  };

  useEffect(() => {
    const fethApi = async () => {
      fetch(serverAddress+ uri, options)
        .then(res => res.blob())
        .then(blob => {
          const url = URL.createObjectURL(blob);
          setFileUrl(url);
          // alert(url)
        });
    };
    fethApi();
  }, [uri]);
  const pageStyle = {
    width: "100px", // Adjust width as needed
    height: "auto", // Adjust height as needed
    margin: "0 auto", // Center the page
    display: "block"
  };
  return (
    <div style={{ display: "flex", justifyContent: "center" }}>
      {fileUrl && (
        <Document file={{ url: fileUrl }} onLoadSuccess={onLoadSuccess}>
          {Array.from(new Array(numPages), (el, index) => (
            <Page key={`page_${index + 1}`} style={pageStyle} pageNumber={index + 1} />
          ))}
        </Document>
      )}
      
     
    </div>
  );
}

export default PdfViewer;
