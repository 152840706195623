import "./Spinner.css";

const Spinner = () => {
  return (
    <>
      <div className="loader"></div>
      <p className="text">Loading please wait...</p>
    </>
  );
};

export default Spinner;
