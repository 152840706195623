import { Grid, Typography } from "@mui/material";
import React from "react";

function VarDateTime({ data, value, id }) {

  function formatDate(value, displayFormat, sourceFormat) {
    const date = new Date(value);

    const options = {
      "MMMM Do YYYY, h:mm:ss a": {
        month: "long",
        day: "numeric",
        year: "numeric",
        hour: "numeric",
        minute: "numeric",
        second: "numeric",
        hour12: true
      },
      dddd: { weekday: "long" },
      "MMM Do YY": { month: "short", day: "numeric", year: "2-digit" },
      "YYYY [escaped] YYYY": { year: "numeric" },
      "MM-DD-YYYY": { month: "2-digit", day: "2-digit", year: "numeric" },
      "DD/MM/YYYY": { day: "2-digit", month: "2-digit", year: "numeric" },
      LT: { hour: "numeric", minute: "numeric", hour12: true },
      LTS: { hour: "numeric", minute: "numeric", second: "numeric", hour12: true },
      L: { month: "2-digit", day: "2-digit", year: "numeric" },
      l: { month: "numeric", day: "numeric", year: "numeric" },
      LL: { month: "long", day: "numeric", year: "numeric" },
      ll: { month: "short", day: "numeric", year: "numeric" },
      LLL: {
        month: "long",
        day: "numeric",
        year: "numeric",
        hour: "numeric",
        minute: "numeric",
        hour12: true
      },
      lll: {
        month: "short",
        day: "numeric",
        year: "numeric",
        hour: "numeric",
        minute: "numeric",
        hour12: true
      },
      LLLL: {
        weekday: "long",
        month: "long",
        day: "numeric",
        year: "numeric",
        hour: "numeric",
        minute: "numeric",
        hour12: true
      },
      llll: {
        weekday: "short",
        month: "short",
        day: "numeric",
        year: "numeric",
        hour: "numeric",
        minute: "numeric",
        hour12: true
      }
    };

    if (displayFormat === "YYYY [escaped] YYYY") {
      return `${date.getFullYear()} escaped ${date.getFullYear()}`;
    } else if (options[displayFormat]) {
      return date.toLocaleDateString("en-US", options[displayFormat]);
    } else {
      return "Invalid format";
    }
  }

  function replaceDataDelimiters(input_date, format) {
    let date_format_delimiter = "";
    if (
      (data?.inputType?.component?.sDisplayFormat || data?.component?.sDisplayFormat)?.includes("-")
    ) {
      date_format_delimiter = "-";
    }
    if (
      (data?.inputType?.component?.sDisplayFormat || data?.component?.sDisplayFormat)?.includes("/")
    ) {
      date_format_delimiter = "/";
    }

    // alert()
    const current_delimiter = !isNaN(input_date.slice(0, 2)) ? input_date[2] : input_date[1];
    return input_date.replaceAll(current_delimiter, date_format_delimiter);
  }


  const dateformat = (date, sourceFormat = "YYYY-MM-DD", returnFormat) => {
    if (date) {
      const parseDate = (date, format) => {
        const parts = String(date)?.split("-");
        const formatParts = format?.toLowerCase()?.split("-");
        const dateObj = {};

        formatParts?.forEach((part, index) => {
          dateObj[part] = parseInt(parts[index], 10);
        });

        return new Date(dateObj.yyyy, dateObj.mm - 1, dateObj.dd);
      };

      const formatDate = (dateObj, format) => {
        const dd = String(dateObj.getDate()).padStart(2, "0");
        const mm = String(dateObj.getMonth() + 1).padStart(2, "0");
        const yyyy = dateObj.getFullYear();

        switch (format) {
          case "dd-mm-yyyy":
            return `${dd}-${mm}-${yyyy}`;
          case "yyyy-dd-mm":
            return `${yyyy}-${dd}-${mm}`;
          case "yyyy-mm-dd":
            return `${yyyy}-${mm}-${dd}`;
          case "mm-dd-yyyy":
            return `${mm}-${dd}-${yyyy}`;
          default:
            return "Invalid date format pattern";
        }
      };

      // Convert sourceFormat and returnFormat to lowercase
      const parsedDate = parseDate(date, sourceFormat?.toLowerCase());

      if (isNaN(parsedDate)) {
        return "Invalid date";
      }

      return formatDate(parsedDate, returnFormat?.toLowerCase());
    } else {
      return "";
    }
  };

  return (
    <Grid item {...data?.grid_props}>
      {/* data?.inputType?.component?.sSourceFormat || data?.component?.sSourceFormat */}
      {/* data?.inputType?.component?.sDisplayFormat || data?.component?.sDisplayFormat */}
      {/* {JSON.stringify(data)} */}
      {/* {JSON.stringify(replaceDataDelimiters(formatDate(value,data?.inputType?.component?.sDisplayFormat|| data?.component?.sDisplayFormat),data?.inputType?.component?.sDisplayFormat || data?.component?.sDisplayFormat))}
        {JSON.stringify(data?.inputType?.component?.sDisplayFormat || data?.component?.sDisplayFormat)} */}

        {/* {dateformat("2012-12-20", "YYYY-MM-DD", "DD-MM-YYYY")} */}
      <Typography {...data?.component?.sProps} id={id}>
        <span
          dangerouslySetInnerHTML={{
            __html: value
              ? replaceDataDelimiters(formatDate(
                dateformat(value, data?.inputType?.component?.sSourceFormat || data?.component?.sSourceFormat, data?.inputType?.component?.sDisplayFormat || data?.component?.sDisplayFormat),
                  data?.inputType?.component?.sDisplayFormat || data?.component?.sDisplayFormat,
                  data?.inputType?.component?.sSourceFormat || data?.component?.sSourceFormat
                ))
              : ""
          }}
        />
      </Typography>
    </Grid>
  );
}

export default VarDateTime;
